<template>
    <div>
        <div class="tab-a">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="formInline">
                <el-form-item label="国家代码:" prop="countryCode">
                    <el-input v-model="formInline.countryCode" placeholder="国家代码" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="地区名称:" prop="countryName">
                    <el-input v-model="formInline.countryName" placeholder="地区名称" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="发送价(欧元):" prop="sendPrice">
                    <el-input v-model="formInline.sendPrice" placeholder="发送价(欧元)"></el-input>
                </el-form-item>
                <el-form-item label="接受价(欧元):" prop="acceptPrice">
                    <el-input v-model="formInline.acceptPrice" placeholder="接受价(欧元)"></el-input>
                </el-form-item>
                <el-form-item label="发送(积分):" prop="sendScore">
                    <el-input v-model="formInline.sendScore" placeholder="发送(积分)"></el-input>
                </el-form-item>
                <el-form-item label="积分:" prop="acceptScore">
                    <el-input v-model="formInline.acceptScore" placeholder="积分"></el-input>
                </el-form-item>
                <el-form-item label="附件加费(积分)	:" prop="addiScore">
                    <el-input v-model="formInline.addiScore" placeholder="附件加费(积分)"></el-input>
                </el-form-item>
            </el-form>
            <div class="botton-a">
                <el-button type="success" size="medium" style="width: 100px;" @click="determine()"
                    :disabled="disabledDine">确定</el-button>
                <el-button type="info" size="medium" style="width: 100px;" @click="cancel()">取消</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { editPrice } from '@/utils/api'
//   import cesfdfds from './HelloWorld.vue'
export default {
    props: ['inputName'],
    data() {
        return {
            formInline: {
                countryCode: '',
                countryName: '',
                sendPrice: '',
                acceptPrice: '',
                sendScore: '',
                acceptScore: '',
                addiScore: '',
            },
            disabledDine: false
        };
    },
    methods: {
        // 修改
        determine() {
            let params = {
                msgPriceId: this.inputName.msgPriceId,
                sendPrice: this.formInline.sendPrice,
                acceptPrice: this.formInline.acceptPrice,
                sendScore: this.formInline.sendScore,
                acceptScore: this.formInline.acceptScore,
                addiScore: this.formInline.addiScore,
            }
            editPrice(params).then(res => {
                if (res.data.syscode == '0000') {
                    this.$message({
                        message: res.data.retValue,
                        type: 'success'
                    });
                }
                this.$emit('closeMain', false);
            })
        },
        cancel(){
            this.$emit('closeMain', false);
        }

    },
    mounted() {

        this.formInline.countryCode = this.inputName.countryCode
        this.formInline.countryName = this.inputName.countryName
        this.formInline.sendPrice = this.inputName.sendPrice
        this.formInline.acceptPrice = this.inputName.acceptPrice
        this.formInline.sendScore = this.inputName.sendScore
        this.formInline.acceptScore = this.inputName.acceptScore
        this.formInline.addiScore = this.inputName.addiScore
    },
    watch: {
        inputName(val) {
            console.log(val);
            this.$set(this.formInline, 'countryCode', val.countryCode,)
            this.$set(this.formInline, 'countryName', val.countryName,)
            this.$set(this.formInline, 'sendPrice', val.sendPrice,)
            this.$set(this.formInline, 'acceptPrice', val.acceptPrice,)
            this.$set(this.formInline, 'sendScore', val.sendScore,)
            this.$set(this.formInline, 'acceptScore', val.acceptScore,)
            this.$set(this.formInline, 'addiScore', val.addiScore,)
        }
    }
};
</script>
  
<style lang="less" scoped>
.tab-a {
    width: 100%;
    height: 35vh;
    border: 1px solid #ccc;

    // background-color: red;
    ::v-deep .el-form {
        display: flex;
        flex-wrap: wrap;
    }

    ::v-deep .el-form-item {
        width: 50%;
        display: flex;
        float: 0 50%;
        margin: 0;
        margin-top: 20px;
        // width: 50%;
    }

    ::v-deep .el-form-item__label {
        padding: 0 0 0 0;
        margin-right: 5px;
        margin-left: 5px;
        width: 40%;
    }

    ::v-deep .el-form-item__content {
        width: 73%;
    }

    ::v-deep .el-input__inner {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;
        width: 87%;
        // width: 40%;
    }

    .botton-a {
        width: 100%;
        height: 15%;
        // background-color: gold;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
}
</style>
  
