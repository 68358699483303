<template>
    <div class="tab">
      <div class="tab-a">
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="客户名称" prop="companyName">
            <el-input v-model="form.companyName" placeholder="请选择客户名称"></el-input>
          </el-form-item>
          <el-button type="primary" @click="onSubmitsearch()" style="margin-left: 15px;" size="medium">搜索</el-button>
          <el-button type="primary" @click="onSubmitReset()" size="medium">重置</el-button>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="tab-b">
        <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading">
          <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
          </el-table-column>
          <el-table-column prop="companyName" label="客户名称" align="center">
          </el-table-column>
          <el-table-column prop="replyContext" label="回复内容" align="center" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="keyWords" label="关键词" align="center" width="155">
          </el-table-column>
          <el-table-column prop="defaultReplyContext"  label="默认回复" align="center">
          </el-table-column>
          <el-table-column  label="审核状态" align="center">
            <template slot-scope="scope">
              {{
                scope.row.auditStatus == '1' ? '未提交' : scope.row.auditStatus == '2' ? '已提交' :
                scope.row.auditStatus == '3' ? '审核未通过' :'审核已通过'
              }}
            </template>
          </el-table-column>
          <el-table-column prop="enabled" label="启动状态" align="center">
            <template slot-scope="scope">
            {{
                scope.row.enabled == 'Y' ? '已启用' : '未启用'
              }}
              </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum" :page-sizes="[20, 40, 60, 100]" :page-size="queryInfo.pagesize"
          :total="schArr.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important;">
        </el-pagination>
      </div>
  
    </div>
  </template>
  
  <script>
  
  import { findWsMsgReplyTemplate  } from '@/utils/api'
  export default {
    data() {
      return {
        queryInfo:{
          pagenum:1,
          pagesize:20
        },
        form: {
            companyName: ''
        },
        tableListt:[],
        schArr:[],
        loading:false,
      }
    },
    methods: {
      handleClick(row) {
        console.log(row);
      },
      handleSizeChange(val) {
        this.queryInfo.pagesize = val
        this.getPageData()
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.queryInfo.pagenum = val
        this.getPageData()
        console.log(`当前页: ${val}`);
      },
      getPageData() {
        let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
        let end = start + this.queryInfo.pagesize;
        this.schArr = this.tableListt.slice(start, end);
        this.$forceUpdate()
      },
      // type序号 - 页面切换递增
      indexAdd(index) {
        const page = this.queryInfo.pagenum // 当前页码
        const pagesize = this.queryInfo.pagesize  // 当前页码
        return index + 1 + (page - 1) * pagesize
      },
      // 搜索
      onSubmitsearch(){
        this.listDataPoints()
      },
      // 重置
      onSubmitReset(){
        this.$refs.form.resetFields();
        this.loading = true
        setTimeout(() => {
          this.listDataPoints()
        }, 500);
      },
      // 数据列表
      listDataPoints(){
        findWsMsgReplyTemplate(this.form).then(res=>{
          if(res.data.syscode == '0000') {
            this.tableListt = res.data.replyList
            this.loading = false
            this.getPageData()
          }
        })
      }
    },
    mounted() {
      this.listDataPoints()
    }
  
  };
  </script>
    
  <style lang="less" scoped>
  .tab {
    width: 100%;
    height: 100%;
  
    .tab-a {
      width: 100%;
      height: 8%;
      // background-color: red;
      display: flex;
      align-items: center;
  
      ::v-deep .el-form {
        display: flex;
        align-items: center;
      }
  
      ::v-deep .el-form-item {
        margin-bottom: 0px;
      }
    }
  
    .tab-b {
      width: 100%;
      height: 92%;
      // background-color: aqua;
    }
  }
  </style>
    
  