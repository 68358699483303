<template>
    <div class="tab">
      <!-- <div class="tab-a">
        <el-button type="primary" @click="exportTable()" size="medium">导出表格</el-button>
      </div> -->
      <!-- 表格 -->
      <div class="tab-b">
        <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading" class="table">
          <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
          </el-table-column>
          <el-table-column prop="id" label="ID" align="center">
          </el-table-column>
          <el-table-column prop="name" label="名称" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="电话" align="center">
          </el-table-column>
          <el-table-column prop="wxNumber" label="微信号" align="center">
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="createTime" label="提交时间" align="center" :show-overflow-tooltip="true">
          </el-table-column>
  
        </el-table>
        <!-- 分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum" :page-sizes="[20, 50, 150]" :page-size="queryInfo.pagesize"
          :total="tableListt.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important;">
        </el-pagination>
      </div>
  
    </div>
  </template>
    
  <script>
  import FileSaver from "file-saver";
  import XLSX from "xlsx";
  import { findServulist, updateYstatus } from '@/utils/api'
  
  export default {
    data() {
      return {
        queryInfo: {
          pagenum: 1,
          pagesize: 20
        },
  
        tableListt: [],
        schArr: [],
        loading: false,
      }
    },
    methods: {
      handleClick(row) {
        console.log(row);
      },
      handleSizeChange(val) {
        this.queryInfo.pagesize = val
        this.getPageData()
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.queryInfo.pagenum = val
        this.getPageData()
        console.log(`当前页: ${val}`);
      },
      getPageData() {
        let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
        let end = start + this.queryInfo.pagesize;
        this.schArr = this.tableListt.slice(start, end);
        this.$forceUpdate()
      },
      // type序号 - 页面切换递增
      indexAdd(index) {
        const page = this.queryInfo.pagenum // 当前页码
        const pagesize = this.queryInfo.pagesize  // 当前页码
        return index + 1 + (page - 1) * pagesize
      },
      // 搜索
      onSubmitsearch() {
        this.listDataPoints()
      },
      // 重置
      onSubmitReset() {
        this.$refs.form.resetFields();
        this.loading = true
        setTimeout(() => {
          this.listDataPoints()
        }, 500);
      },
      // 数据列表
      listDataPoints() {
        
        findServulist(this.queryInfo).then(res => {
          console.log(res);
          if (res.data.syscode == '0000') {
            this.tableListt = res.data.MessageList
            this.loading = false
            this.getPageData()
          }
        })
      },
      // 导出表格
      exportTable() {
        // 设置当前日期
        let time = new Date();
        let year = time.getFullYear();
        let month = time.getMonth() + 1;
        let day = time.getDate();
        let name = year + "-" + month + "-" + day;
        //  .table要导出的是哪一个表格
        var wb = XLSX.utils.table_to_book(document.querySelector(".table"));
        var wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array"
        });
        try {
          //  name+'.xlsx'表示导出的excel表格名字
          FileSaver.saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            name +'操作日志'+ ".xlsx"
          );
        } catch (e) {
          if (typeof console !== "undefined") console.log(e, wbout);
        }
        return wbout;
      }
  },
  mounted() {
    this.listDataPoints()
  }
    
    };
  </script>
      
  <style lang="less" scoped>
  .tab {
    width: 100%;
    height: 100%;
  
    .tab-a {
      width: 100%;
      height: 8%;
      // background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;
  
      ::v-deep .el-form {
        display: flex;
        align-items: center;
      }
  
      ::v-deep .el-form-item {
        margin-bottom: 0px;
      }
    }
  
    .tab-b {
      width: 100%;
      height: 100%;
      // background-color: aqua;
    }
  }
  </style>
      
    