<template>
    <div>
        <div style="display: flex; justify-content:center;">
            <h3>
                <span style=" text-align: center; ">
                    文案编写
                </span>
            </h3>
        </div>
        <div style="width: 100%; display: flex; justify-content: flex-end; align-items: center;">
            <el-select v-model="value" placeholder="请选择类别(必选)" style="margin-right: 30px;">
                <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.label">
                </el-option>
            </el-select>
            <el-upload class="upload-demo" action="http://test.lefenglian.top/" :on-change="handleChange"
                :auto-upload="false">
                <el-button type="primary" size="medium" icon="el-icon-upload2">上传图片</el-button>
            </el-upload>
        </div>
        <!-- 富文本 -->
        <quill-editor ref="myQuillEditor" v-model="content" :options="editorOption" @on-success="onEditorChange($event)">
        </quill-editor>
        <!-- 公告发布按钮 -->
        <div class="releaseBtn">
            <el-button type="danger" size="medium" @click="releaseBtn">发布公告</el-button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { findSerupload, findSertext } from '@/utils/api.js'
export default {
    components: {},
    props: {},
    data() {
        return {
            selectedFile: null,
            content: null,
            editorOption: {
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                        ["blockquote", "code-block"], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
                        [{ script: "sub" }, { script: "super" }], // 上标/下标
                        [{ indent: "-1" }, { indent: "+1" }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ["small", false, "large", "huge"] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ["clean"], // 清除文本格式
                       
                    ], //工具菜单栏配置
                    
                },
                placeholder: '请在这里添加文案', //提示
                readyOnly: false, //是否只读
                theme: 'snow', //主题 snow/bubble
                syntax: true, //语法检测
                height: '50px' // 这里设置为你想要的高度
            },
            value: '',
            optionss: [
                {
                    label: '外贸动态'
                }, {
                    label: '行业案例'
                },
                {
                    label: '外贸知识'
                }, {
                    label: '公司荣誉'
                },
            ]

        };
    },
    watch: {},
    computed: {},
    methods: {
        onEditorChange(editor) {
            this.content = editor.html;
        },
        handleImageClick(enev) {
            console.log(enev);
        },
        handleChange(file) {
            // this.$refs.myQuillEditor.quillEditor.setContent(this.content);
            let fd = file.raw;
            var formData = new FormData();
            formData.append('file', fd)
            findSerupload(formData).then(res => {
                if (res.data.syscode == '0000') {
                    this.$message({
                        message: res.data.reValue,
                        type: 'success'
                    });
                    this.content += `<img src='${res.data.url}'></img>`
                } else {
                    this.$message.error(res.data.reValue);
                }
            })

        },

        //提交富文本输入的值和图片
        releaseBtn() {
            let params = {
                typeName: this.value,
                dataValue: this.content
            }
            axios({
                method: 'post',
                data: params,
                url: 'http://106.13.213.246:8001/gw/save/text',
                headers: { 'Token': sessionStorage.getItem('token') }
            }).then(res => {
                if (res.data.syscode == '0000') {
                    this.$message({
                        message: res.data.reValue,
                        type: 'success'
                    });
                }else {
                    this.$message.error(res.data.reValue);
                }
            })
        }
    },
    created() { },
    mounted() { }
};
</script>
<style lang="less" scoped>
.releaseBtn {
    text-align: center;
    margin-top: 15px;
}

::v-deep .el-upload-list {
    display: none;
}
::v-deep .ql-container {
    height: 500px !important;
}
</style>