<template>
  <div class="tab">
    <div class="tab-a">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="企业名称" prop="companyName">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="用户名称" prop="userName">
          <el-input v-model="form.userName"></el-input>
        </el-form-item>
        <el-form-item label="操作时间" prop="operateTime">
          <el-date-picker v-model="form.operateTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" @click="onSubmit()" style="margin-left: 15px;" size="medium">搜索</el-button>
        <el-button type="primary" @click="onSubmitt()" size="medium">重置</el-button>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="tab-b">
      <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading">
        <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
        </el-table-column>
        <el-table-column prop="companyName" label="企业名称" align="center">
        </el-table-column>
        <el-table-column prop="userName" label="用户名称" align="center">
        </el-table-column>
        <el-table-column prop="logContext" label="日志内容" align="center">
        </el-table-column>
        <el-table-column prop="operateTime" label="操作时间" align="center">
        </el-table-column>
        <el-table-column  label="日志操作类型" align="center">
          <template slot-scope="scope">
            {{
              scope.row.logCatalog == '1' ? '任务' : scope.row.logCatalog == '2' ? '帐户'
             : scope.row.logCatalog == '3' ? '客户' : scope.row.logCatalog == '4' ? '登录' : 
             scope.row.logCatalog == '5' ? '消息' : scope.row.logCatalog == '6' ? '搜索' : '系统'
            }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作类型" align="center">
          <template slot-scope="scope">
            {{
              scope.row.operateType == '1' ? '插入' : scope.row.operateType == '2' ? '删除'
             : scope.row.operateType == '3' ? '编辑' : scope.row.operateType == '4' ? '交易' : ''
            }}
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum" :page-sizes="[20,10000,100000]" :page-size="queryInfo.pagesize"
        :total="schArr.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important;">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { findUserLog} from '@/utils/api'
export default {
  data() {
    return {
      queryInfo: {
        pagenum: 1,
        pagesize: 20
      },
      form: {
        companyName: '',
        userName: '',
        operateTime:''
      },
      tableData: [],
      schArr:[],
      loading:false
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    // type序号 - 页面切换递增
    indexAdd(index) {
      const page = this.queryInfo.pagenum // 当前页码
      const pagesize = this.queryInfo.pagesize  // 当前页码
      return index + 1 + (page - 1) * pagesize
    },
    getPageData() {
      let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
      let end = start + this.queryInfo.pagesize;
      this.schArr = this.tableData.slice(start, end);
      this.$forceUpdate()
    },
    // 搜索
    onSubmit(){
      this.listDataRis()
    },
    // 重置
    onSubmitt(){
      this.$refs.form.resetFields();
      this.loading = true
      setTimeout(() => {
        this.listDataRis()
        
      }, 500);
    },
    // 列表数据查询
    listDataRis(){
      findUserLog(this.form).then(res=>{
        if(res.data.syscode == '0000') {
          this.tableData = res.data.logList
          this.loading = false
          this.getPageData()
        }
      })
    }
  },
  mounted(){
    this.listDataRis()
  }

};
</script>
  
<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .tab-a {
    width: 100%;
    height: 8%;
    // background-color: red;
    display: flex;
    align-items: center;
  }

  .tab-b {
    width: 100%;
    height: 92%;
    // background-color: aqua;
  }
}

::v-deep .el-form {
  display: flex;
  align-items: center;
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}
</style>
  
