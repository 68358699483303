<template>
    <!-- 一页面 -->
    <div>
        <div v-if="onePage" class="tab-a">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" ref="formInline">
                <el-form-item label="客户ID:" prop="companyId" :rules="[
                    { required: true, message: '客户ID不能为空' },
                    { type: 'number', message: '客户ID必须为数字值' }
                ]">
                    <el-input v-model.number="formInline.companyId" placeholder="客户ID"></el-input>

                </el-form-item>
                <el-form-item label="客户名称:" prop="companyName" :rules="[
                    { required: true, message: '客户名称不能为空' },
                    { pattern: /^[\u0391-\uFFE5A-Za-z]+$/, message: '客户名称必须为文字或者英文格式' }
                ]">
                    <el-input v-model="formInline.companyName" placeholder="客户名称"></el-input>
                </el-form-item>
                <el-form-item label="客户联系人:" prop="contact" :rules="[
                    { required: true, message: '客户联系人不能为空' },
                    { pattern: /^[\u0391-\uFFE5A-Za-z]+$/, message: '客户联系人必须为文字或者英文格式' }
                ]">
                    <el-input v-model="formInline.contact" placeholder="客户联系人"></el-input>
                </el-form-item>
                <el-form-item label="联系电话:" prop="contactPhone" :rules="[
                    { required: true, message: '联系电话不能为空' },
                    { pattern: /^[0-9]*$/, message: '请输入正确的电话格式' }
                ]">
                    <el-input v-model="formInline.contactPhone" placeholder="联系电话"></el-input>
                </el-form-item>
                <el-form-item label="企业类型:" prop="companyType">
                    <el-select v-model="formInline.companyType" placeholder="企业类型">
                        <el-option label="个人客户" value="1"></el-option>
                        <el-option label="企业客户" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客户状态:" prop="status">
                    <el-select v-model="formInline.status" placeholder="客户状态">
                        <el-option label="已激活" value="Y"></el-option>
                        <el-option label="未激活" value="N"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="当前积分:" prop="curScore" :rules="[
                    { required: true, message: '客户ID不能为空' },
                    { type: 'number', message: '客户ID必须为数字值' }
                ]">
                    <el-input v-model.number="formInline.curScore" placeholder="当前积分"></el-input>
                </el-form-item>
                <el-form-item label="办公地址:" prop="address" :rules="[
                    { required: true, message: '办公地址不能为空' },
                    { pattern: /^[\a-\z\A-\Z0-9\u4e00-\u9fe5]+$/, message: '办公地址必须为中文,数字,英文格式' }
                ]">
                    <el-input v-model="formInline.address" placeholder="办公地址"></el-input>
                </el-form-item>
                <el-form-item label="客户等级:" prop="level" :rules="[
                    { required: true, message: '客户等级不能为空' },
                    { type: 'number', message: '客户等级必须为数字值' }
                ]">
                    <el-input v-model.number="formInline.level" placeholder="客户等级"></el-input>
                </el-form-item>
                <el-form-item label="到期日期:" prop="serviceEndDate">
                    <el-date-picker type="datetime" v-model="formInline.serviceEndDate" placeholder="选择结束时间"
                        @change="TimeChange"></el-date-picker>
                </el-form-item>
                <el-form-item label="企业行业:" prop="industryId">
                    <el-input v-model="formInline.industryId" placeholder="企业行业"></el-input>
                </el-form-item>
            </el-form>
            <div class="botton-a">
                <el-button type="success" size="medium" style="width: 100px;" @click="determine()"
                    :disabled="disabledDine">确定</el-button>
                <el-button type="info" size="medium" style="width: 100px;" @click="cancel()">取消</el-button>
            </div>
        </div>

        <!-- 二页面 -->
        <div v-if="twoPages" class="tab-b">
            <el-form :inline="true" :model="formInlineend" class="demo-form-inline">
                <el-form-item label="客户名称:">
                    <el-select v-model="formInlineend.companyId" placeholder="客户名称" filterable :disabled="disablediS">
                        <el-option :label="item.companyName" :value="item.companyId" v-for="(item, index) in fileList"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="账户名称:" prop="userName">
                    <el-input v-model="formInlineend.userName" placeholder="账户名称"
                        onkeyup="value=value.replace(/[^\w\u4E00-\u9FA5]/g,'')" :disabled="disablediS"></el-input>
                </el-form-item>
                <el-form-item label="登陆账号:" prop="loginName">
                    <el-input v-model="formInlineend.loginName" placeholder="登陆账号" onkeyup="value=value.replace(/[\s]/g,'')"
                        :disabled="disablediS"></el-input>
                </el-form-item>
                <el-form-item label="权限标识:">
                    <el-select v-model="formInlineend.type" clearable placeholder="权限标识(选填)" :disabled="disablediS">
                        <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="联系电话:" prop="telphone" :rules="[
                    { required: true, message: '联系电话不能为空' },
                    { pattern: /^[0-9]*$/, message: '请输入正确的联系电话哦 !' }
                ]">
                    <el-input v-model="formInlineend.telphone" placeholder="联系电话" :disabled="disablediS"></el-input>
                </el-form-item>
                <el-form-item label="备注:">
                    <el-input v-model="formInlineend.remark" placeholder="备注" :disabled="disablediS"></el-input>
                </el-form-item>
                <el-form-item label="硬件ID:">
                    <el-input v-model="formInlineend.hardwareOnlyId" placeholder="硬件ID" @input="handleInput"></el-input>
                </el-form-item>
                <el-form-item label="IP状态:">
                    <el-select v-model="formInlineend.IPState" clearable placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="botton-a">
                <el-button type="success" size="medium" style="width: 100px;" @click="establish()"
                    v-if="disablediS == false">创建</el-button>
                <el-button type="success" size="medium" style="width: 100px;" @click="modifyEstablish()"
                    v-else>修改</el-button>
            </div>
        </div>
        <!-- 三页面 -->
        <div v-if="threePages" class="tab-c">
            <el-form :inline="true" :model="formInlineRend" class="demo-form-inline">
                <el-form-item label="客户名称:">

                    <el-select v-model="formInlineRend.companyId" placeholder="客户名称" filterable>
                        <el-option :label="item.companyName" :value="item.companyId" v-for="(item, index) in fileList"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客服账号:">
                    <el-input v-model="formInlineRend.loginName" placeholder="客服账号"
                        onkeyup="value=value.replace(/[\W]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="初始密码:">
                    <el-input v-model="ures" placeholder="初始密码" :disabled="true"></el-input>
                </el-form-item>
            </el-form>
            <div class="botton-a">
                <el-button type="success" size="medium" style="width: 100px;" @click="confirmToadd()"
                    :disabled="disabledDineRend">确定新增</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { addCustome, editCustomer, importUserName, addAccount, addServicers, findServupdate } from '@/utils/api'
export default {
    props: ['inputName', 'listName', 'listData'],
    data() {
        var checkAge = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('年龄不能为空'));
            }
        }
        return {

            formInline: {
                companyId: '',
                companyName: '',
                contact: '',
                contactPhone: '',
                companyType: '',
                industryId: '',
                curScore: '',
                address: '',
                level: '',
                serviceEndDate: '',
                createTime: '',
                status: '',
            },
            onePage: false,
            twoPages: false,
            threePages: false,
            // 判断输入框中的值是否为空
            flag: true,
            // 按钮禁用
            disabledDine: false,
            ifExist: '',
            // 判断是否是编辑还是新增
            editTextContent: '',
            // 监听父组件的值
            fileList: [],
            formInlineend: {
                companyId: '',
                userName: '',
                loginName: '',
                mac: '',
                telphone: '',
                remark: '',
                hardwareOnlyId: '',
                IPState: 'N',
                type:''
            },
            // 新增客服
            formInlineRend: {
                companyId: '',
                loginName: '',
            },
            ures: '111111',
            disabledDineRend: true,
            options: [{
                value: 'Y',
                label: '已绑定'
            }, {
                value: 'N',
                label: '未绑定'
            }],
            disablediS: false,
            optionss: [{
                value: '1',
                label: '一级权限'
            }, {
                value: '2',
                label: '二级权限'
            }],
        };
    },
    methods: {
        login() {
            this.$router.push({ name: 'Catts', })
        },
        // 获取创建时间
        TimeChangee() {
            var time = new Date();
            time.getYear(); //获取当前年份
            time.getFullYear(); //获取完整的年份(4位,1970-???)
            time.getMonth(); //获取当前月份(0-11,0代表1月)
            time.getDate(); //获取当前日(1-31)
            time.getDay(); //获取当前星期X(0-6,0代表星期天)
            time.getTime(); //获取当前时间(从1970.1.1开始的毫秒数)
            time.getHours(); //获取当前小时数(0-23)
            time.getMinutes(); //获取当前分钟数(0-59)
            time.getSeconds(); //获取当前秒数(0-59)
            time.getMilliseconds(); //获取当前毫秒数(0-999)
            time.toLocaleDateString(); //获取当前日期
            var mytime = time.toLocaleTimeString(); //获取当前时间
            //获取当天日期
            function getTotay() {
                var time = new Date();
                var year = time.getFullYear();
                var month = time.getMonth() + 1;
                var date = time.getDate();
                return year + '-' + add0(month) + '-' + add0(date);
            }
            //修改月、天的格式，保持两位数显示
            function add0(m) {
                return m < 10 ? '0' + m : m
            }
            var today = getTotay() + ' ' + mytime;
            this.formInline.createTime = today
        },
        // 获取时间
        TimeChange(e) {
            var that = this
            var date = new Date(e);
            var y = date.getFullYear(); // 年
            var m = date.getMonth() + 1; // 月
            m = m < 10 ? ('0' + m) : m;
            var d = date.getDate(); // 日
            d = d < 10 ? ('0' + d) : d;
            var h = date.getHours(); // 时
            h = h < 10 ? ('0' + h) : h;
            var min = date.getMinutes(); // 分
            min = min < 10 ? ('0' + min) : min;
            var s = date.getSeconds(); // 秒
            s = s < 10 ? ('0' + s) : s;
            that.formInline.serviceEndDate = y + '-' + m + '-' + d + ' ' + h + ':' + min + ':' + s;
        },
        // 新增客服
        determine() {
            this.TimeChangee()
            const arr = document.querySelector("body > div.el-dialog__wrapper > div > div.el-dialog__body > div > div > form").querySelectorAll('input')
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].value == '') {
                    this.isEmpty = true  //当有值未空时改变判断的值
                    break
                }
            }
            console.log(this.editTextContent);
            if (this.inputName == '4') {
                if (!this.isEmpty) {
                    delete this.formInline.createTime
                    editCustomer(this.formInline).then(res => {
                        if (res.data.syscode == '0000') {
                            this.$message({
                                message: res.data.retValue,
                                type: 'success'
                            });
                            this.$emit('closeMain', false);
                            this.$refs.formInline.resetFields();
                        }
                    })
                } else {
                    console.log(!this.isEmpty);
                    this.$message({
                        message: '请完成编辑的内容 !',
                        type: 'warning'
                    });
                }
            } else {
                if (!this.isEmpty) {
                    addCustome(this.formInline).then(res => {
                        if (res.data.syscode == '0000') {
                            this.$message({
                                message: res.data.retValue,
                                type: 'success'
                            });

                            this.$emit('closeMain', false);
                            this.$refs.formInline.resetFields();
                        } else {
                            this.$message({
                                message: res.data.retValue,
                                type: 'error'
                            });
                        }
                    })
                } else {
                    this.$message({
                        message: '请完成添加的内容 !',
                        type: 'warning'
                    });
                }
            }


        },
        // 取消弹框
        cancel() {
            this.$emit('closeMain', false);
        },
        // 新增主账号列表下拉框
        importUserData() {
            importUserName().then(res => {
                if (res.data.syscode == '0001') {
                    let list = JSON.stringify(res.data.companyList)
                    localStorage.setItem('listData', list)
                    let listDataA = localStorage.getItem('listData')
                    this.fileList = JSON.parse(listDataA)
                }

            })
        },

        // 新增
        sendAdd() {
            addAccount(this.formInlineend).then(res => {
                console.log(res);
                if (res.data.syscode == '0000') {
                    this.$message({
                        message: res.data.retValue,
                        type: 'success'
                    });
                    this.$emit('closeMain', false);
                    this.$refs.formInline.resetFields();
                } else {
                    this.$message({
                        message: res.data.retValue,
                        type: 'error'
                    });
                }
            })
        },
        // 新增主账号
        establish() {
            this.formInlineend.loginName = this.formInlineend.loginName.replace(/\s+/g, '')
            this.sendAdd()
        },
        //修改硬件ID
        modifyEstablish() {
            let params = {
                hardwareOnlyId: this.formInlineend.hardwareOnlyId,
                userId: this.formInlineend.userId,
            }
            findServupdate(params).then(res => {
                console.log(res);
                if (res.data.syscode == '0000') {
                    this.$message({
                        message: res.data.retValue,
                        type: 'success'
                    });
                    this.$emit('closeMain', false);
                } else {
                    this.$message({
                        message: res.data.retValue,
                        type: 'error'
                    });
                }
            })
        },
        //监听是否输入了硬件ID值
        handleInput(val) {
            if (val !== '') {
                // 用户输入了值
                this.formInlineend.IPState = 'Y'; // 设置为已绑定
            } else {
                // 用户未输入值
                this.formInlineend.IPState = 'N'; // 重置状态
            }
        },
        // 新增客服
        confirmToadd() {
            addServicers(this.formInlineRend).then(res => {
                if (res.data.syscode == '0000') {
                    this.$message({
                        message: res.data.retValue,
                        type: 'success'
                    });
                    this.$emit('closeMain', false);
                    this.$refs.formInlineRend.resetFields();
                }
            })
        }

    },
    mounted() {
        console.log(this.inputName);
        if (this.inputName == '1') {
            this.onePage = true
        } else if (this.inputName == '2') {
            this.twoPages = true
            console.log(this.formInlineend);
            this.importUserData()
        } else if (this.inputName == '硬件ID') {
            this.twoPages = true
            this.formInlineend = { ...this.listData }
            this.formInlineend.companyId = this.listData.companyName
            this.disablediS = true
        } else if (this.inputName == '3') {
            this.threePages = true
            this.importUserData()
        }

    },
    computed: {
        changeData() {
            const { companyId, companyName, contact, contactPhone, companyType, industryId, curScore, address, level, serviceEndDate, status } = this.formInline
            return { companyId, companyName, contact, contactPhone, companyType, industryId, curScore, address, level, serviceEndDate, status }
            // 如果值比较多
            //return {nameinput}
        },
        confirmToaData() {
            const { companyId, loginName } = this.formInlineRend
            return { companyId, loginName }
        }
    },
    watch: {
        inputName: {
            handler(val) {
                console.log(val);
                if (val == '硬件ID') {
                    console.log(this.listData);
                    this.formInlineend = { ...this.listData }
                } else {
                    let arr = {
                        companyId: '',
                        userName: '',
                        loginName: '',
                        mac: '',
                        telphone: '',
                        remark: ''
                    }
                    this.formInlineend = { ...arr }
                    this.disablediS = false
                }
            }
        },

        listData: {
            handler(val) {
                if (this.inputName == '硬件ID') {
                    this.formInlineend = { ...val }
                    this.formInlineend.companyId = val.companyName
                    this.disablediS = true
                }
            }
        },
        // inputName:{
        //     handler(val) {
        //         if(val == '硬件ID') {
        //             this.formInlineend = { ...val }
        //         }else {
        //             console.log(this.formInlineend);
        //         }
        //         console.log(val);
        //     }
        // },
        // changeData: {
        //     // handler(newValue, oldValue) {
        //     //     if(newValue.companyId == '' && newValue.companyName == '' && newValue.contact == '' && newValue.contactPhone == '' && newValue.companyType == ''  && newValue.curScore == '' && newValue.address == '' && newValue.level == '' &&
        //     //     newValue.serviceEndDate == '' && newValue.status == '' ) {
        //     //         this.disabledDine = false
        //     //     }else {
        //     //         this.disabledDine = true
        //     //     }
        //     // }
        // },
        confirmToaData: {
            handler(newValue) {
                console.log(newValue);
                if (newValue.companyId == '' || newValue.loginName == '') {
                    this.disabledDineRend = true
                } else {
                    this.disabledDineRend = false
                }
            }
        }

    }

};
</script>
  
<style lang="less" scoped>
::v-deep .el-select {
    width: 100%;
}
.tab-a {
    width: 100%;
    // height: 50vh;
    border: 1px solid #ccc;

    // background-color: red;
    ::v-deep .el-form {
        display: flex;
        flex-wrap: wrap;
    }

    ::v-deep .el-form-item {
        width: 50%;
        display: flex;
        float: 0 50%;
        margin: 0;
        margin-top: 20px;
        // width: 50%;
    }

    ::v-deep .el-form-item__label {
        padding: 0 0 0 0;
        margin-right: 5px;
        margin-left: 5px;
        width: 40%;
    }

    ::v-deep .el-form-item__content {
        width: 73%;
    }

    ::v-deep .el-input__inner {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;
        width: 87%;
        // width: 40%;
    }

    .botton-a {
        width: 100%;
        height: 15%;
        // background-color: gold;
        display: flex;
        margin:10px;
        align-items: center;
        justify-content: space-evenly;
    }
}

.tab-b {
    width: 100%;
    // height: 35vh;
    border: 1px solid #ccc;

    // background-color: red;
    ::v-deep .el-form {
        display: flex;
        flex-wrap: wrap;
    }

    ::v-deep .el-form-item {
        width: 50%;
        display: flex;
        float: 0 50%;
        margin: 0;
        margin-top: 20px;
        // width: 50%;
    }

    ::v-deep .el-form-item__label {
        padding: 0 0 0 0;
        margin-right: 5px;
        margin-left: 5px;
        width: 30%;
    }

    ::v-deep .el-form-item__content {
        width: 73%;
    }

    ::v-deep .el-input__inner {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;
        width: 87%;
    }

    .botton-a {
        width: 100%;
        height: 15%;
        // background-color: gold;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 40px;
    }
}

.tab-c {
    width: 100%;
    // height: 25vh;
    border: 1px solid #ccc;

    // background-color: red;
    ::v-deep .el-form {
        display: flex;
        flex-wrap: wrap;
    }

    ::v-deep .el-form-item {
        width: 50%;
        display: flex;
        float: 0 50%;
        margin: 0;
        margin-top: 20px;
        // width: 50%;
    }

    ::v-deep .el-form-item__label {
        padding: 0 0 0 0;
        margin-right: 5px;
        margin-left: 5px;
        width: 40%;
    }

    ::v-deep .el-form-item__content {
        width: 73%;
    }

    ::v-deep .el-input__inner {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;

    }

    .botton-a {
        width: 100%;
        height: 15%;
        // background-color: gold;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 40px;
    }
}
</style>
  
