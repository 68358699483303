<template>
  <div class="tab">
    <div class="tab-a">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="客户名称:" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请选择客户名称"></el-input>
        </el-form-item>
        <el-form-item label="账单类型" prop="transationTypeId">
          <el-select v-model="form.transationTypeId" placeholder="请选择账单类型">
            <el-option :label="item.name" :value="item.value" v-for="(item, index) in listDataType"
              :key="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间段:">
          <el-date-picker v-model="form.test1" type="date" placeholder="选择开始日期" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd">
          </el-date-picker>

          <el-date-picker v-model="form.test2" type="date" placeholder="选择结束日期" format="yyyy-MM-dd"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-button type="primary" @click="onSubmitsearch()" style="margin-left: 15px;" size="medium">搜索</el-button>
        <el-button type="primary" @click="onSubmitReset()" size="medium">重置</el-button>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="tab-b">
      <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading">
        <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
        </el-table-column>
        <el-table-column prop="companyName" label="客户名称" align="center">
        </el-table-column>
        <el-table-column prop="transactionID" label="账单ID" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="账单类型" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.transationTypeId == '1' ? '充值' : scope.row.transationTypeId == '2' ? '消费' :
                scope.row.transationTypeId == '3' ? '提现' : scope.row.transationTypeId == '4' ? '转出' :
                  scope.row.transationTypeId == '5' ? '转入' : scope.row.transationTypeId == '6' ? '订单兑换' : '退费'
            }}
          </template>
        </el-table-column>
        <el-table-column prop="transScore" label="积分" align="center" width="155">
        </el-table-column>
        <el-table-column prop="transDesc" label="说明" align="center">
        </el-table-column>
        <el-table-column prop="status" label="交易状态" align="center" width="110" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
              scope.row.status == 'Y' ? '已成功' : '未成功'
            }}
          </template>
        </el-table-column>
        <el-table-column prop="transDatetime" label="产生时间" align="center">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum" :page-sizes="[20, 40, 60, 100]" :page-size="queryInfo.pagesize"
        :total="schArr.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important;">
      </el-pagination>
      <!-- 审核 -->
      <el-dialog title="提示" :visible.sync="centerDialogVisibleen" width="20%" center :close-on-click-modal="false">
        <span>你审核好了吗, 是否继续?</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisibleen = false" type="info" size="medium">取 消</el-button>
          <el-button type="success" @click="confirmUnbinding()" size="medium">继 续</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>

import { findCustomerBill } from '@/utils/api'
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      queryInfo: {
        pagenum: 1,
        pagesize: 20
      },
      form: {
        companyName: '',
        test1: '',
        test2: '',
        transationTypeId: ''
      },
      tableListt: [],
      schArr: [],
      loading: false,
      centerDialogVisibleen: false,
      params: {
        transactionID: '',
        status: '',
        transScore: '',
        compangID: ''
      },
      listDataType: [
        { name: '充值', value: '1' },
        { name: '消费', value: '2' },
        { name: '提现', value: '3' },
        { name: '转出', value: '4' },
        { name: '转入', value: '5' },
        { name: '订单兑换', value: '6' },
        { name: '退费', value: '7' },
      ]
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.queryInfo.pagesize = val
      this.getPageData()
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.queryInfo.pagenum = val
      this.getPageData()
      console.log(`当前页: ${val}`);
    },
    getPageData() {
      let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
      let end = start + this.queryInfo.pagesize;
      this.schArr = this.tableListt.slice(start, end);
      this.$forceUpdate()
    },
    // type序号 - 页面切换递增
    indexAdd(index) {
      const page = this.queryInfo.pagenum // 当前页码
      const pagesize = this.queryInfo.pagesize  // 当前页码
      return index + 1 + (page - 1) * pagesize
    },
    // 搜索
    onSubmitsearch() {
      console.log(this.form);
      this.listDataPoints()
    },
    // 重置
    onSubmitReset() {
      this.$refs.form.resetFields();
      this.loading = true
      setTimeout(() => {
        this.Date()
        this.listDataPoints()

      }, 500);
    },
    // 数据列表
    listDataPoints() {
      console.log(this.form);
      let params = {
        companyName: this.form.companyName,
        test1: this.form.test1,
        test2: this.form.test2,
        transationTypeId: this.form.transationTypeId == '' ? '0' : this.form.transationTypeId
      }
      findCustomerBill(params).then(res => {
        if (res.data.syscode == '0000') {
          this.tableListt = res.data.CustomerBillList
          this.loading = false
          this.getPageData()
        } else {
          this.tableListt = []
          this.getPageData()
          this.loading = false
          this.$message({
            message: res.data.retValue,
            type: 'success'
          });
        }
      })
    },
    //获取当前年月日
    Date() {
      const nowDate = new Date();
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate(),
      };
      const newmonth = date.month > 10 ? date.month : "0" + date.month;
      const newday = date.date > 10 ? date.date : "0" + date.date;
      this.form.test1 = date.year + "-" + newmonth + "-" + newday;
      this.form.test2 = date.year + "-" + newmonth + "-" + newday;
    },
  },
  mounted() {
    this.Date()
    this.listDataPoints()
  }

};
</script>
  
<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .tab-a {
    width: 100%;
    height: 8%;
    // background-color: red;
    display: flex;
    align-items: center;

    ::v-deep .el-form {
      display: flex;
      align-items: center;
    }

    ::v-deep .el-form-item {
      margin-bottom: 0px;
    }
  }

  .tab-b {
    width: 100%;
    height: 92%;
    // background-color: aqua;
  }
}
</style>
  
