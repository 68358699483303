<template>
    <div>
       <!-- 编辑页面 -->
       <div class="tab-a">
            <el-form :inline="true" :model="formInlineren" class="demo-form-inline" >
                <el-form-item label="客户通道ID:" prop="companyChannelId" >
                    <el-input v-model.number="formInlineren.companyChannelId" placeholder="只能输入字母和数字" onKeyUp="value=value.replace(/[\u4e00-\u9fa5/\s+/]|[^a-zA-Z0-9\u4E00-\u9FA5]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="通道号码:" prop="channelCode">
                    <el-input v-model="formInlineren.channelCode" placeholder="只能输入字母和数字" onKeyUp="value=value.replace(/[^\d|chun]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="申请时间:" prop="applyTime">
                    <el-date-picker v-model="formInlineren.applyTime" type="date" placeholder="选择日期"
                        :picker-options="pickerOptions" value-format="yyyy-MM-dd">
                    </el-date-picker>
                   
                </el-form-item>
                <el-form-item label="有效期:" prop="validityEndDate">
                    <el-date-picker v-model="formInlineren.validityEndDate" type="date" placeholder="选择日期"
                        :picker-options="pickerOptions" value-format="yyyy-MM-dd">
                    </el-date-picker>
                    <!-- <el-input v-model="formInlineren.validityEndDate" placeholder="有效期"></el-input> -->
                </el-form-item>
                <el-form-item label="通道权重:" prop="channelWeightLevel">
                    <el-select v-model="formInlineren.channelWeightLevel" placeholder="通道权重">
                        <el-option label="低" value="0"></el-option>
                        <el-option label="中" value="1"></el-option>
                        <el-option label="高" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否开启自动回复:" prop="isAutoReply">
                    <el-select v-model="formInlineren.isAutoReply" placeholder="是否开启自动回复" >
                        <el-option label="开启" value="Y"></el-option>
                        <el-option label="不开启" value="N"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="24小时最大回复:" prop="maxNumDay">
                    <el-input v-model.number="formInlineren.maxNumDay" placeholder="只能输入数字" onKeyUp="value=value.replace(/[^\d]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="续订积分:" prop="scorePerMonth">
                    <el-input v-model="formInlineren.scorePerMonth" placeholder="只能输入数字" onKeyUp="value=value.replace(/[^\d]/g,'')"></el-input>
                </el-form-item>
            </el-form>
            <div class="botton-a">
                <el-button type="success" size="medium" style="width: 100px;" @click="determine()"
                   :disabled = "disabledDine" >添加</el-button>
                <el-button type="info" size="medium" style="width: 100px;" @click="cancel()">取消</el-button>
            </div>
        </div>
    </div>
   </template>
 
  <script>
  import {addChannel} from '@/utils/api'
//   import cesfdfds from './HelloWorld.vue'
     export default {
        props: ['listcompanyId'],
        data() {
            return {
                pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
                formInlineren:{
                    companyId:'',
                    companyChannelId:'',
                    channelCode:'',
                    maxNumDay :'',
                    applyTime:'',
                    channelWeightLevel:'',
                    isAutoReply:'',
                    validityEndDate:'',
                    scorePerMonth:''
                },
                disabledDine:true
            };
        },
        methods :{
            // 添加
            determine(){
                addChannel(this.formInlineren).then(res=>{  
                    if(res.data.syscode == '0000'){
                        this.$message({
                        message: res.data.retValue,
                        type: 'success'
                        });
                        this.$emit('closeMain', false);
                    }
                })
            },
            // 取消
            cancel(){
                this.$emit('closeMain', false);
            }
        },
        mounted(){
                this.formInlineren.companyId = this.listcompanyId
            
        },
        computed: {
	changeData() {
		const {companyId,companyChannelId,channelCode,maxNumDay,applyTime,channelWeightLevel,isAutoReply,validityEndDate,scorePerMonth}=this.formInlineren
		return {companyId,companyChannelId,channelCode,maxNumDay,applyTime,channelWeightLevel,isAutoReply,validityEndDate,scorePerMonth}
		// 如果值比较多
		//return {nameinput}
	},
},
        watch:{
            listcompanyId(val){
                this.formInlineren.companyId = val
            },
            changeData:{
            handler(newValue, oldValue) {
                if(newValue.companyId == '' || newValue.companyChannelId == '' ||newValue.channelCode == '' ||newValue.maxNumDay == '' ||newValue.applyTime == '' ||
                newValue.channelWeightLevel == '' ||newValue.isAutoReply == '' ||newValue.validityEndDate == '' ||newValue.scorePerMonth == '') {
                    this.disabledDine = true
                }else {
                    this.disabledDine = false
                }
            }
        },
        }
       };
   </script>
   
   <style lang="less" scoped>
 .tab-a {
    width: 100%;
    height: 40vh;
    border: 1px solid #ccc;

    // background-color: red;
    ::v-deep .el-form {
        display: flex;
        flex-wrap: wrap;
    }

    ::v-deep .el-form-item {
        width: 50%;
        display: flex;
        float: 0 50%;
        margin: 0;
        margin-top: 20px;
        // width: 50%;
    }

    ::v-deep .el-form-item__label {
        padding: 0 0 0 0;
        margin-right: 5px;
        margin-left: 5px;
        width: 40%;
    }

    ::v-deep .el-form-item__content {
        width: 73%;
    }

    ::v-deep .el-input__inner {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;
        width: 87%;
        // width: 40%;
    }

    .botton-a {
        width: 100%;
        height: 15%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 30px;
    }
}
   </style>
   
 