import Vue from 'vue'   //引入Vue
import Router from 'vue-router'  //引入vue-router
Vue.use(Router)  //Vue全局使用Router

import HelloWorld from '@/components/HelloWorld'
import login from '@/components/login'

import Catts from '@/components/cats/Catts'
import CatsPage from '@/components/cats/CatsPage'
import CatsPage2 from '@/components/cats/CatsPage2'
import CatMain from '@/components/cats/CatMain'
// 客户账户管理
import customerPoints from '@/components/cats/financial/customerPoints'
import customerDetails from '@/components/cats/financial/customerDetails'
import customerUnitrice from '@/components/cats/financial/customerUnitrice'
// 消息管理
import messageTemplate from '@/components/cats/management/messageTemplate'
import messageTemplateen from '@/components/cats/management/messageTemplateen'
import sendingTask from '@/components/cats/management/sendingTask'
// 统计报表
import rechargeStatistics from '@/components/cats/statistical/rechargeStatistics'
import billingStatistics from '@/components/cats/statistical/billingStatistics'
// 系统管理
import userManagement from '@/components/cats/managementSend/userManagement'
import changePassword from '@/components/cats/managementSend/changePassword'
import journal from '@/components/cats/managementSend/journal'
//官网管理
import information from '@/components/cats/managementTs/information'
import Copy from '@/components/cats/managementTs/Copy'
import copywritingQuery from '@/components/cats/managementTs/copywritingQuery'
const routes = [
     {
        path: '/',
        name: 'login',
        component: login,
      },
      {
        path: '/cat',
        name: 'HelloWorld',
        component: HelloWorld,
        children: [
            {
                path: 'Catts',
                name: 'Catts',
                component: Catts
            },
            {
                path: '/cat/CatMain',
                name: 'CatMain',
                component: CatMain,
                meta :{
                    requierAuth :true
                }
            }, {
                path: '/cat/catspage',
                name: 'CatsPage',
                component: CatsPage,
                meta :{
                    requierAuth :true
                }
            }, {
                path: '/cat/catspage2',
                name: 'CatsPage2',
                component: CatsPage2,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/financial/customerPoints',
                name: 'customerPoints',
                component: customerPoints,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/financial/customerDetails',
                name: 'customerDetails',
                component: customerDetails,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/financial/customerUnitrice',
                name: 'customerUnitrice',
                component: customerUnitrice,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/management/messageTemplate',
                name: 'messageTemplate',
                component: messageTemplate,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/management/messageTemplateen',
                name: 'messageTemplateen',
                component: messageTemplateen,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/management/sendingTask',
                name: 'sendingTask',
                component: sendingTask,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/statistical/rechargeStatistics',
                name: 'rechargeStatistics',
                component: rechargeStatistics,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/statistical/billingStatistics',
                name: 'billingStatistics',
                component: billingStatistics,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/managementSend/userManagement',
                name: 'userManagement',
                component: userManagement,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/managementSend/changePassword',
                name: 'changePassword',
                component: changePassword,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cat/managementSend/journal',
                name: 'journal',
                component: journal,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cats/managementTs/information',
                name: 'information',
                component: information,
                meta :{
                    requierAuth :true
                }
            },{
                path: '/cats/managementTs/Copy',
                name: 'Copy',
                component: Copy,
                meta :{
                    requierAuth :true
                }
            },
            {
                path: '/cats/managementTs/copywritingQuery',
                name: 'copywritingQuery',
                component: copywritingQuery,
                meta :{
                    requierAuth :true
                }
            },
        ]
    }]

const router = new Router({
    base: process.env.BASE_URL,
    routes
})
export default router


router.beforeEach((to,from,next)=>{
    if (to.path==='/') return next();
    //获取token
    const tokenStr= sessionStorage.getItem('token')
    if(!tokenStr) return next('/')
    next()
  })