<template>
    <div class="tabs-a">
        <div class="botton-a">
            <el-button type="primary" size="medium" style="width: 130px;" @click="addChannel()">添加通道</el-button>
        </div>
        <div class="tabs-b">
            <el-table :data="tableListt" stripe style="width: 100%" border>
                <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
                </el-table-column>
                <el-table-column prop="companyChannelId" label="通道ID" align="center">
                </el-table-column>
                <el-table-column prop="channelCode" label="号码通道" align="center" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="maxNumDay" label="24H" align="center">
                </el-table-column>
                <el-table-column  label="通道权重" align="center">
                    <template slot-scope="scope">
                        {{
                            scope.row.channelWeightLevel == '0' ? '低' : scope.row.channelWeightLevel == '1' ? '中' :
                                scope.row.channelWeightLevel == '2' ? '高' : ''
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="applyTime" label="申请时间" align="center" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="自动回复" align="center">
                    <template slot-scope="scope">
                        {{ scope.row.isAutoReply == 'Y' ? '开启中' : scope.row.isAutoReply == 'N' ? '关闭中' : '' }}
                    </template>
                </el-table-column>
                <el-table-column prop="validityEndDate" label="有效期" align="center" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="scorePerMonth" label="续订积分" align="center">
                </el-table-column>
            </el-table>
        </div>
        <div class="tabs-c">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[20, 40, 60, 100]" :page-size="queryInfo.pagesize"
                :total="tableListt.length" layout="->, total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
         <!-- 通道弹框 -->
       <el-dialog title="添加通道" :visible.sync="dialogChannel" center :append-to-body='true'
      custom-class="lzg-dialog-height" :lock-scroll="false" width="40%" :close-on-click-modal="false">
       <addChannel :listcompanyId = 'companyId' v-on:closeMain='closeMain'></addChannel>
    </el-dialog>
    </div>
</template>

<script>
import { seachChannel } from '@/utils/api'
import addChannel from './addChannel.vue';
export default {
    components:{
        addChannel
    },
    props: ['listName'],
    data() {
        return {
            queryInfo: {
                pagenum: 1,
                pagesize: 20
            },
            tableListt: [],
            companyId: '',
            dialogChannel:false
        };
    },
    methods: {
        handleSizeChange(val) {
            this.queryInfo.pagesize = val
            this.getPageData()
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.queryInfo.pagenum = val
            this.getPageData()
            console.log(`当前页: ${val}`);
        },
        // type序号 - 页面切换递增
        indexAdd(index) {
            const page = this.queryInfo.pagenum // 当前页码
            const pagesize = this.queryInfo.pagesize  // 当前页码
            return index + 1 + (page - 1) * pagesize
        },
        getPageData() {
            let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
            let end = start + this.queryInfo.pagesize;
            this.schArr = this.tableListt.slice(start, end);
            console.log(this.schArr);
            this.$forceUpdate()
        },
        addChannel(){
            this.dialogChannel = true
        },
        // 列表
        tablistData() {
            let params = {
                companyId: this.companyId
            }
            seachChannel(params).then(res => {
                if (res.data.syscode == '0000') {
                    this.tableListt = res.data.channelList
                    this.getPageData()
                }
            })
        },
        // 接收子组件传来的参数
            closeMain(val) {
            console.log(val);
            this.dialogChannel = val
            if (val == false) {
                this.tablistData()
            }
            }
    },
    mounted() {
        this.companyId = this.listName
        this.tablistData()
    },
    watch: {
        listName(val) {
            console.log(val);
            if (val) {
                this.companyId = val
                this.tablistData()
            }

        }
    }
};
</script>
  
<style lang="less" scoped>
.tabs-a {
    width: 100%;
    height: 50vh;
.botton-a {
    width: 100%;
    height: 4vh;
    display: flex;
    align-items: center;

}
    .tabs-b {
        width: 100%;
        height: 43vh;
    }

    .tabs-c {
        width: 100%;
        height: 3vh;
    }
}
</style>
  
