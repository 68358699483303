<template>
    <div>
        <!-- 编辑页面 -->
        <div class="tab-a">
            <el-form :inline="true" :model="formInlineren" class="demo-form-inline">
                <el-form-item label="客户ID:" prop="companyId">
                    <el-input v-model.number="formInlineren.companyId" placeholder="客户ID" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="客户名称:" prop="companyName"  :rules="[
                    { required: true, message: '办公地址不能为空' },
                    { pattern: /^[\a-\z\A-\Z0-9\u4e00-\u9fe5]+$/, message: '办公地址必须为中文,数字,英文格式' }
                ]">
                    <el-input v-model="formInlineren.companyName" placeholder="客户名称"></el-input>
                </el-form-item>
                <el-form-item label="客户联系人:" prop="contact">
                    <el-input v-model="formInlineren.contact" placeholder="客户联系人"></el-input>
                </el-form-item>
                <el-form-item label="联系电话:" prop="contactPhone">
                    <el-input v-model="formInlineren.contactPhone" placeholder="联系电话"></el-input>
                </el-form-item>
                <el-form-item label="企业类型:" prop="companyType">
                    <el-select v-model="formInlineren.companyType" placeholder="企业类型">
                        <el-option label="个人客户" value="1"></el-option>
                        <el-option label="企业客户" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客户状态:" prop="status">
                    <el-select v-model="formInlineren.status" placeholder="客户状态">
                        <el-option label="已激活" value="Y"></el-option>
                        <el-option label="未激活" value="N"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="当前积分:" prop="curScore">
                    <el-input v-model.number="formInlineren.curScore" placeholder="当前积分"></el-input>
                </el-form-item>
                <el-form-item label="办公地址:" prop="address">
                    <el-input v-model="formInlineren.address" placeholder="办公地址"></el-input>
                </el-form-item>
                <el-form-item label="客户等级:" prop="level">
                    <el-input v-model.number="formInlineren.level" placeholder="客户等级"></el-input>
                </el-form-item>
                <el-form-item label="到期日期:" prop="serviceEndDate">
                    <el-date-picker type="datetime"  v-model="formInlineren.serviceEndDate" placeholder="选择结束时间"  @change="TimeChange"></el-date-picker>
                    <!-- <el-input v-model="formInline.serviceEndDate" placeholder="到期日期"></el-input> -->
                </el-form-item>
                <el-form-item label="企业行业:" prop="industryId">
                    <el-input v-model="formInlineren.industryId" placeholder="企业行业"></el-input>
                </el-form-item>
            </el-form>
            <div class="botton-a">
                <el-button type="warning" size="medium" style="width: 100px;" @click="determine()"
                    :disabled="disabledDine">修改</el-button>
                <el-button type="info" size="medium" style="width: 100px;" @click="cancel()">取消</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { editCustomer} from '@/utils/api'
export default {
    props: ['listName'],
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            formInlineren: {
                companyId: '',
                companyName: '',
                contact: '',
                contactPhone: '',
                companyType: '',
                industryId: '',
                curScore: '',
                address: '',
                level: '',
                serviceEndDate: '',
                status: ''
            },
            disabledDine:false
        };
    },
    methods: {
        // 获取时间
        TimeChange(e){
            var that = this
	var date = new Date(e);
	var y = date.getFullYear(); // 年
	var m = date.getMonth() + 1; // 月
	m = m < 10 ? ('0' + m) : m;
	var d = date.getDate(); // 日
	d = d < 10 ? ('0' + d) : d;
	var h = date.getHours(); // 时
	h = h < 10 ? ('0' + h) : h;
	var min = date.getMinutes(); // 分
	min = min < 10 ? ('0' + min) : min;
	var s = date.getSeconds(); // 秒
	s = s < 10 ? ('0' + s) : s;
	that.formInlineren.serviceEndDate = y + '-' + m + '-' + d + ' '+ h + ':' + min + ':' + s;
        },
        determine(){
            editCustomer(this.formInlineren).then(res=>{
                console.log(res);
                console.log(this.formInlineren);
                if(res.data.syscode == '0000'){
                    this.$message({
                    message: res.data.retValue,
                    type: 'success'
                    });
                    this.$emit('closeMain', false);
                }
            })
        },
          // 取消弹框
          cancel() {
            this.$emit('closeMain', false);
        }
    },
   mounted(){
    this.formInlineren.companyId = this.listName.companyId
    this.formInlineren.companyName = this.listName.companyName
    this.formInlineren.contact = this.listName.contact
    this.formInlineren.contactPhone = this.listName.contactPhone
    this.formInlineren.companyType = this.listName.companyType
    this.formInlineren.industryId = this.listName.industryId
    this.formInlineren.curScore = this.listName.curScore
    this.formInlineren.address = this.listName.address
    this.formInlineren.level = this.listName.level
    this.formInlineren.serviceEndDate = this.listName.serviceEndDate
    this.formInlineren.status = this.listName.status
   },
   watch:{
    // 监听父组件传来的参数
    listName(val){
        this.$set(this.formInlineren,'companyId',val.companyId,)
        this.$set(this.formInlineren,'companyName',val.companyName,)
        this.$set(this.formInlineren,'contact',val.contact,)
        this.$set(this.formInlineren,'contactPhone',val.contactPhone,)
        this.$set(this.formInlineren,'companyType',val.companyType,)
        this.$set(this.formInlineren,'industryId',val.industryId,)
        this.$set(this.formInlineren,'curScore',val.curScore,)
        this.$set(this.formInlineren,'address',val.address,)
        this.$set(this.formInlineren,'level',val.level,)
        this.$set(this.formInlineren,'serviceEndDate',val.serviceEndDate,)
        this.$set(this.formInlineren,'status',val.status,)
        console.log(val);
    }
   }
};
</script>
  
<style lang="less" scoped>
 .tab-a {
    width: 100%;
    height: 45vh;
    border: 1px solid #ccc;

    // background-color: red;
    ::v-deep .el-form {
        display: flex;
        flex-wrap: wrap;
    }

    ::v-deep .el-form-item {
        width: 50%;
        display: flex;
        float: 0 50%;
        margin: 0;
        margin-top: 20px;
        // width: 50%;
    }

    ::v-deep .el-form-item__label {
        padding: 0 0 0 0;
        margin-right: 5px;
        margin-left: 5px;
        width: 40%;
    }

    ::v-deep .el-form-item__content {
        width: 73%;
    }

    ::v-deep .el-input__inner {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0px;
        width: 87%;
        // width: 40%;
    }

    .botton-a {
        width: 100%;
        height: 15%;
        // background-color: gold;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
}
</style>
  
