<template>
  <div class="tab">
    <div class="tab-a">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="客户名称">
          <el-input v-model="form.companyName" placeholder="请选择客户名称"></el-input>
        </el-form-item>
        <el-form-item label="客户状态">
          <el-select v-model="form.status" placeholder="请选择客户状态">
            <el-option :label="item" :value="index" v-for="(item, index) in lengt" :key="index"></el-option>
            <!-- <el-option label="未启用" value="N"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item label="企业类型">
          <el-select v-model="form.companyType" placeholder="请选择企业类型">
            <el-option label="个人客户" value="1"></el-option>
            <el-option label="企业客户" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="onSubmitsearch()" style="margin-left: 15px;" size="medium">搜索</el-button>
        <el-button type="primary" @click="onSubmitReset()" size="medium">重置</el-button>
        <el-button type="primary" @click="newCustomers()" size="medium">新增客户</el-button>

      </el-form>
    </div>
    <!-- 表格 -->
    <div class="tab-b">
      <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading">
        <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
        </el-table-column>
        <el-table-column prop="companyId" label="客户ID" align="center">
        </el-table-column>
        <el-table-column prop="companyName" label="客户名称" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="contact" label="客户联系人" align="center" width="150">
        </el-table-column>
        <el-table-column prop="contactPhone" label="客户联系电话" align="center" width="155">
        </el-table-column>
        <el-table-column label="企业类型" align="center">
          <template slot-scope="scope">
            {{ scope.row.companyType == '1' ? '个人客服' : scope.row.companyType == '2' ? '企业客服' : '' }}
          </template>
        </el-table-column>
        <el-table-column label="行业" align="center" prop='industryId' width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="address" label="办公地址" align="center">
        </el-table-column>
        <el-table-column prop="curScore" label="当前积分" align="center">
        </el-table-column>
        <el-table-column prop="serviceEndDate" label="到期日期" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="level" label="客户等级" align="center">
        </el-table-column>
        <el-table-column prop="status" label="客户状态" align="center">
          <template slot-scope="scope">
            {{ scope.row.status == 'Y' ? '已激活' : scope.row.status == 'N' ? '未激活' : scope.row.status == 'M' ? '已禁用' : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" width="150" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="操作" width="170" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="danger" size="small" @click="editDanger(scope.row)"> 编辑</el-button>
            <el-button type="primary" size="small" @click="passageway(scope.row.companyId)">通道</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum" :page-sizes="[20, 10000, 100000]" :page-size="queryInfo.pagesize"
        :total="tableListt.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important;">
      </el-pagination>
    </div>
    <!-- 弹框 -->
    <el-dialog title="新增客户" :visible.sync="dialogVisible" center :append-to-body='true' custom-class="lzg-dialog-height"
      :lock-scroll="false" :close-on-click-modal="false">
      <subPage :inputName="name" v-on:closeMain='closeMain' :listName="list"></subPage>
    </el-dialog>
    <!-- 编辑弹框 -->
    <el-dialog title="编辑信息" :visible.sync="dialogVisibleel" center :append-to-body='true' custom-class="lzg-dialog-height"
      :lock-scroll="false" :close-on-click-modal="false">
      <editPage v-on:closeMain='closeMain' :listName="list"></editPage>
    </el-dialog>
    <!-- 通道弹框 -->
    <el-dialog title="客服通道" :visible.sync="dialogChannel" center :append-to-body='true' custom-class="lzg-dialog-height"
      :lock-scroll="false" :close-on-click-modal="false">
      <customerChannel :listName="listData"></customerChannel>
    </el-dialog>
  </div>
</template>

<script>
import subPage from '@/components/secondlevelPage/subPage.vue'
import editPage from '@/components/secondlevelPage/editPage.vue'
import customerChannel from '@/components/secondlevelPage/customerChannel.vue'
import { findMessage } from '@/utils/api'
export default {
  components: {
    subPage,
    editPage,
    customerChannel
  },
  data() {
    return {
      queryInfo: {
        pagenum: 1,
        pagesize: 20
      },
      form: {
        companyName: '',
        status: '',
        companyType: ''
      },
      tableListt: [],
      dialogVisible: false,
      name: '',
      lengt: {
        Y: '已激活',
        N: '未激活',
      },
      schArr: [],
      // 加载动画
      loading: false,
      list: '',
      // /编辑弹框
      dialogVisibleel: false,
      // 客户通道
      dialogChannel: false,
      listData: '',
      schArr: []
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);

    },
    handleSizeChange(val) {
      this.queryInfo.pagesize = val
      this.getPageData()
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.queryInfo.pagenum = val
      this.getPageData()
      console.log(`当前页: ${val}`);
    },
    getPageData() {
      let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
      let end = start + this.queryInfo.pagesize;
      this.schArr = this.tableListt.slice(start, end);
      console.log(this.schArr);
      this.$forceUpdate()
    },
    // type序号 - 页面切换递增
    indexAdd(index) {
      const page = this.queryInfo.pagenum // 当前页码
      const pagesize = this.queryInfo.pagesize  // 当前页码
      return index + 1 + (page - 1) * pagesize
    },
    // 新增客服
    newCustomers() {
      this.name = '1'
      this.dialogVisible = true
    },
    // 编辑按钮
    editDanger(e) {
      this.list = e
      this.dialogVisibleel = true
    },
    // 通道按钮
    passageway(e) {
      this.listData = e
      this.dialogChannel = true
    },
    // 搜索
    onSubmitsearch() {
      if (this.form.companyName == '' && this.form.companyType == '' && this.form.status == '') {
        this.$notify({
          title: '警告',
          message: '请填写搜索内容 !',
          type: 'warning'
        });
      } else {
        let params = {
          companyName: this.form.companyName,
          status: this.form.status == '' ? 0 : this.form.status,
          companyType: this.form.companyType == '' ? 0 : this.form.companyType
        }
        this.loading = true
        findMessage(params).then(res => {
          if (res.data.syscode == '0000') {
            this.loading = false
            this.tableListt = res.data.MessageList
            this.getPageData()
          }
        })
      }


    },
    // 重置
    onSubmitReset() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.form.companyName = ''
        this.form.status = ''
        this.form.companyType = ''
        this.tableList()
      }, 500);

    },
    // 表格数据
    tableList() {
      let params = {
        companyName: '',
        status: 0,
        companyType: 0
      }
      findMessage(params).then(res => {
        this.tableListt = res.data.MessageList
        this.getPageData()
        console.log(this.form);

      })
    },
    // 接收子组件传来的参数
    closeMain(val) {
      this.dialogVisible = val
      this.dialogVisibleel = val
      if (val == false) {
        this.tableList()
      }
    }
  },
  mounted() {
    this.tableList()
  }

};
</script>
  
<style lang="less" scoped>


.tab {
  width: 100%;
  height: 100%;

  .tab-a {
    width: 100%;
    height: 8%;
    // background-color: red;
    display: flex;
    align-items: center;

    ::v-deep .el-form {
      display: flex;
      align-items: center;
    }

    ::v-deep .el-form-item {
      margin-bottom: 0px;
    }
  }

  .tab-b {
    width: 100%;
    height: 92%;
    // background-color: aqua;
  }

}

// ::v-deep .el-dialog__wrapper {
//     display: flex !important;
//     align-items: center !important;
//   }
::v-deep .el-dialog {
  margin-top: 0 !important;
}
</style>
  
