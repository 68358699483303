<template>
  <div class="tab">
    <div class="tab-a">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="客户名称" prop="companyName">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="账户名称" prop="userName">
          <el-input v-model="form.userName"></el-input>
        </el-form-item>
        <el-form-item label="登录账号" prop="loginName">
          <el-input v-model="form.loginName"></el-input>
        </el-form-item>
        <el-form-item label="账户状态" prop="enabled">
          <el-select v-model="form.enabled" placeholder="请选择活动区域">
            <el-option label="已激活" value="Y"></el-option>
            <el-option label="未激活" value="N"></el-option>
            <el-option label="禁用" value="S"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="onSubmitsearch()" style="margin-left: 15px;" size="medium">搜索</el-button>
        <el-button type="primary" @click="onSubmitReset()" size="medium">重置</el-button>
        <el-button type="primary" @click="newCustomers()" size="medium">新增主账号</el-button>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="tab-b">
      <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading">
        <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
        </el-table-column>
        <el-table-column prop="userId" label="账号ID" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="companyName" label="客户名称" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="userName" label="账号名称" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="loginName" label="登录账号" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="passWord" label="登录密码" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <!-- <el-table-column prop="mac" label="MAC" align="center" :show-overflow-tooltip="true">
        </el-table-column> -->
        <el-table-column prop="telphone" label="联系电话" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="账号状态" align="center">
          <template slot-scope="scope">
            {{
              scope.row.enable == 'Y' ? '已激活' : scope.row.enable == 'N' ? '未激活' : scope.row.enable == 'S' ? '已禁用' : ''
            }}
          </template>
        </el-table-column>
        <el-table-column prop="LastLoginDate" label="最近登录时间" width="120" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="createDateTime" label="创建时间" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="serviceEndDate" label="到期时间" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="IP" label="IP" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="IPRegion" label="IP地址" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="IPCopy" label="IPCopy" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="IPRegionCopy" label="IPRegionCopy" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="loginFlag" label="是否开启验证" align="center" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
              scope.row.loginFlag == true ? '是' : '否'
            }}
          </template>
        </el-table-column>
        <el-table-column prop="hardwareOnlyId" label="硬件ID" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="IP和硬件绑定" align="center" >
          <template slot-scope="scope">
            {{ scope.row.IPState == 'Y' ? '已绑定' : '未绑定' }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="195" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" @click="centerDialog(scope.row.userId)" style="margin-right: 10px;">
              账号状态</el-button>
            <el-dropdown>
              <el-button type="warning" size="mini">账号操作</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-button type="warning" @click="unBinding(scope.row)" size="small">解绑</el-button>
                <el-button type="danger" @click="modifyHardwareID(scope.row)" size="small">修改硬件ID</el-button>
                <el-button type="info" @click="allowLogin(scope.row)" size="small">允许登录</el-button>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum" :page-sizes="[20, 10000, 100000]" :page-size="queryInfo.pagesize"
        :total="tableData.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important; ">
      </el-pagination>
    </div>
    <!-- 弹框 -->
    <el-dialog title="新增客户" :visible.sync="dialogVisible" center :append-to-body='true' custom-class="lzg-dialog-height"
      :lock-scroll="false" :close-on-click-modal="false">
      <subPage :inputName="name" v-on:closeMain='closeMain' :listData="dataList"></subPage>
    </el-dialog>
    <!-- 账号状态 -->
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="20%" center :close-on-click-modal="false">
      <span>账号状态选择</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="et_val($event)" type="success" id="Y">已激活</el-button>
        <el-button type="warning" @click.native="et_val($event)" id="N">未激活</el-button>
        <el-button type="danger" @click.native="et_val($event)" id="S">禁用</el-button>
      </span>
    </el-dialog>
    <!-- 解绑 -->
    <el-dialog title="提示" :visible.sync="centerDialogVisibleen" width="20%" center :close-on-click-modal="false">
      <span>是否解绑改账户, 是否继续?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisibleen = false" type="info" size="medium">取 消</el-button>
        <el-button type="warning" @click="confirmUnbinding()" :disabled="disabledunDing" size="medium">解 绑</el-button>
      </span>
    </el-dialog>
    <!-- 允许地区登录 -->
    <el-dialog title="提示" :visible.sync="allowRegionalLogin" width="20%" center :close-on-click-modal="false">
      <span>是否允许登录, 是否继续?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="notAllow()" type="info" size="medium">不允许</el-button>
        <el-button type="warning" @click="allow()"  size="medium">允许</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import subPage from '@/components/secondlevelPage/subPage.vue'
import { findAccountManage, setEnable, setIPState, findSerflag } from '@/utils/api'
export default {
  components: {
    subPage
  },
  data() {
    return {
      queryInfo: {
        pagenum: 1,
        pagesize: 20
      },
      total: 0,
      pagesize: 20,
      form: {
        companyName: '',
        userName: '',
        enabled: '',
        loginName: ''
      },
      tableData: [],
      dialogVisible: false,
      name: '',
      loading: false,
      centerDialogVisible: false,
      userId: '',
      centerDialogVisibleen: false,
      params: {
        userId: '',
        IPState: ''
      },
      disabledunDing: false,
      schArr: [],
      dataList: {},
      allowRegionalLogin:false,
      listItem:{}
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.queryInfo.pagesize = val
      this.getPageData()
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.queryInfo.pagenum = val
      this.getPageData()
      console.log(`当前页: ${val}`);
    },
    // type序号 - 页面切换递增
    indexAdd(index) {
      const page = this.queryInfo.pagenum // 当前页码
      const pagesize = this.queryInfo.pagesize  // 当前页码
      return index + 1 + (page - 1) * pagesize
    },
    getPageData() {
      let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
      let end = start + this.queryInfo.pagesize;
      this.schArr = this.tableData.slice(start, end);
      this.$forceUpdate()
    },
    // 新增主账号
    newCustomers() {
      this.name = '2'
      this.dialogVisible = true
    },
    // 搜索
    onSubmitsearch() {
      if (this.companyName == '' && this.userName == '' && this.enabled == '') {
        this.$message.error('请输入搜索的内容哦!');
      } else {
        this.tabListData()
      }
    },
    // 重置
    onSubmitReset() {
      this.loading = true;
      this.$refs.form.resetFields();
      this.tabListData()

    },
    centerDialog(e) {
      console.log(e);
    },
    // 修改状态
    et_val(e) {
      let params = {
        userId: this.userId,
        enable: e.currentTarget.id
      }
      setEnable(params).then(res => {
        console.log(res);
        if (res.data.syscode == '0000') {
          this.$message({
            message: res.data.retValue,
            type: 'success'
          });
          this.centerDialogVisible = false
          this.loading = true
          this.tabListData()
        }
      })
    },
    // 账号状态
    centerDialog(val) {
      this.userId = val
      this.centerDialogVisible = true
    },
    // 解绑
    unBinding(val) {
      this.centerDialogVisibleen = true
      if (val.IPState == 'N') {
        this.disabledunDing = true
      } else {
        this.disabledunDing = false

        this.params.IPState = val.IPState
        this.params.userId = val.userId

      }
    },
    //修改硬件ID
    modifyHardwareID(val) {
      console.log(val);
      this.name = '硬件ID'
      this.dataList = val
      console.log(this.dataList);
      this.dialogVisible = true
    },
    //允许美国和北京地区登录
    allowLogin(item) {
      this.allowRegionalLogin = true
      this.listItem = item
    },
    sendAllow(e){
      let params = {
        flag: e,
        userId: this.listItem.userId
      }
      findSerflag(params).then(res=>{
        if(res.data.syscode == '0000'){
          this.$message({
            message: res.data.reValue,
            type: 'success'
          });
          this.allowRegionalLogin = false
        }

      })
    },
    //不允许登录
    notAllow(){
      this.sendAllow('true')
      this.tabListData()
    },
    //允许
    allow(){
      this.sendAllow('false')
      this.tabListData()
    },
    // 解绑确定按钮
    confirmUnbinding() {
      setIPState(this.params).then(res => {
        if (res.data.syscode == '0000') {
          this.$message({
            type: 'success',
            message: res.data.retValue,
          });
          this.loading = true
          this.centerDialogVisibleen = false
          this.tabListData()
        }
      })
    },
    // 接收子组件传来的参数
    closeMain(val) {
      this.dialogVisible = val
      if (val == false) {
        this.tabListData()
        
      }
    },
    // 表格数据
    tabListData() {
      let params = {
        companyName: this.form.companyName,
        userName: this.form.userName,
        enabled: this.form.enabled == '' ? '0' : this.form.enabled,
        loginName: this.form.loginName
      }
      findAccountManage(params).then(res => {
        console.log(res);
        if (res.data.syscode == '0000') {
          this.loading = false
          this.tableData = res.data.MessageList
          this.total = res.data.MessageList.length;
          this.getPageData()
        }
      })
    }
  },
  mounted() {
    console.log(this.$route.params)
    this.tabListData()
  }

};
</script>
  
<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .tab-a {
    width: 100%;
    height: 8%;
    // background-color: red;
    display: flex;
    align-items: center;
  }

  .tab-b {
    width: 100%;
    height: 92%;
    // background-color: aqua;
  }
}

::v-deep .el-form {
  display: flex;
  align-items: center;
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}

::v-deep .el-button .el-button--default .el-button--small .bottonCle {
  background-color: #333 !important;
}

.persdsd {
  padding-bottom: 0 !important;
  border: none !important;

  .el-message-box__content {
    padding: 0 !important;
  }

  .el-message-box__btns {
    height: 80px;
    background-color: #465979;
    text-align: center;

    .el-button {
      height: 45px;
      width: 160px;
      font-size: 20px;
    }

    .messageBoxP_cancelBtn {
      color: #3395f0;
      background-color: #465979;
      border: 1px solid #3395f0;
    }

    .messageBoxP_confirmBtn {
      color: #fff;
      background-color: #f58194 !important;
      border: #f58194;
    }
  }
}
</style>
  
