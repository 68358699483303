import Vue from 'vue'
import App from './App.vue'
import router  from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 新增下面两个导入配置
import VueClipboard from 'vue-clipboard2'
/*导入axios*/
import axios from "axios";


import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor);

// 在 main.js 或类似的入口文件中添加以下代码

// 禁用F12
window.addEventListener('keydown', function(event) {
  if (event.key === 'F12' || event.keyCode === 123) {
    event.preventDefault();
  }else if (event.key === 'F5' || event.keyCode === 116) {
    event.preventDefault();
  }
  
});
// // 禁用右键点击
// window.addEventListener('contextmenu', function(event) {
//   event.preventDefault();
// });
/*全局绑定axios*/
Vue.prototype.$axios = axios;
Vue.use(ElementUI);
Vue.use(VueClipboard);
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
