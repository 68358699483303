<template>
    <div class="main">
        <header class="header">
            <i :class="isCollapse == false ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
                style="font-size: 29px; padding-left: 15px; cursor: pointer;" @click="expand()"></i>
            <img src="../assets/images/logo.png" alt="" @dragstart.prevent>
            <el-button type="primary" round style="right: 20px; position: absolute;" @click="logoutOfLogin"
                size="small">退出登录</el-button>
        </header>
        <div class="leftMenu">

            <el-menu :default-active="activeIndex" router class="el-menu-vertical-demo" @select="handleSelect"
                :unique-opened='true' background-color="#333" text-color="#fff" active-text-color="#409EFF"
                :collapse="isCollapse">
                <template v-for="item in listItem">
                    <el-submenu v-if="item.children" :index="item.path"
                        :route="{ path: item.path, query: { name: item.name } }">
                        <template slot="title">
                            <i :class="item.icon"></i>
                            <span slot="title">{{ item.name }}</span>
                        </template>
                        <router-link v-for="(citem, cindex) in item.children" :to="citem.path" :key="cindex">
                            <el-menu-item :index="citem.path">
                                <img :src="citem.icon" alt="" style="margin-right: 10px;">
                                <span slot="title">{{ citem.name }}</span>
                            </el-menu-item>
                        </router-link>
                    </el-submenu>
                </template>
            </el-menu>
            <div class="leftMenu-text">
                武汉定制推运维系统
            </div>
        </div>
        <div class="mainContent"> <router-view></router-view></div>
    </div>
 
</template>

<script>
export default {
    name: "left-menu",
    data() {
        return {
            listItem: [],
            items: [
                {
                    icon: "el-icon-user-solid",
                    name: "客服系统",
                    path: "fund",
                    children: [
                        {
                            path: "/cat/Catts", name: "客户信息管理", icon: require('../assets/icon/xx.png')
                        },
                        {
                            path: "/cat/catspage", name: "客户账户管理", icon: require('../assets/icon/zh.png')
                        },
                        {
                            path: "/cat/catspage2", name: "客户日志查询", icon: require('../assets/icon/rz.png')
                        },
                        {
                            path: "/cat/CatMain", name: "客户服务管理", icon: require('../assets/icon/fw.png')
                        },
                    ]
                },
                {
                    icon: "el-icon-s-finance",
                    name: "账务管理",
                    path: "info",
                    children: [
                        {
                            path: "/cat/financial/customerPoints", name: "客户积分管理", icon: require('../assets/icon/jf.png')
                        },
                        {
                            path: "/cat/financial/customerDetails", name: "客户明细账单", icon: require('../assets/icon/zd.png')
                        },
                        {
                            path: "/cat/financial/customerUnitrice", name: "客户单价说明", icon: require('../assets/icon/dj.png')
                        },
                    ]
                },
                {
                    icon: "el-icon-chat-dot-square",
                    name: "消息管理",
                    path: "infoo",
                    children: [
                        {
                            path: "/cat/management/messageTemplate", name: "消息模板", icon: require('../assets/icon/xxi.png')
                        },
                        {
                            path: "/cat/management/messageTemplateen", name: "回复消息模板", icon: require('../assets/icon/xxi1.png')
                        },
                        {
                            path: "/cat/management/sendingTask", name: "消息发送任务", icon: require('../assets/icon/rz.png')
                        },
                    ]
                },
                {
                    icon: "el-icon-document",
                    name: "统计报表",
                    path: "infoi",
                    children: [
                        {
                            path: "/cat/statistical/rechargeStatistics", name: "充值统计", icon: require('../assets/icon/cz.png')
                        },
                        {
                            path: "/cat/statistical/billingStatistics", name: "账单统计", icon: require('../assets/icon/zd.png')
                        },
                    ]
                },
                {
                    icon: "el-icon-setting",
                    name: "系统管理",
                    path: "infos",
                    children: [
                        {
                            path: "/cat/managementSend/journal", name: "日志管理", icon: require('../assets/icon/yhu.png')
                        },
                        {
                            path: "/cat/managementSend/userManagement", name: "用户管理", icon: require('../assets/icon/yhu.png')
                        },
                        {
                            path: "/cat/managementSend/changePassword", name: "修改密码", icon: require('../assets/icon/mm.png')
                        },
                    ]
                },
                {
                    icon: "el-icon-setting",
                    name: "官网管理",
                    path: "infop",
                    children: [
                        {
                            path: "/cats/managementTs/information", name: "信息管理", icon: require('../assets/icon/yhu.png')
                        },
                        {
                            path: "/cats/managementTs/Copy", name: "文案管理", icon: require('../assets/icon/yhu.png')
                        },
                        {
                            path: "/cats/managementTs/copywritingQuery", name: "文案查询", icon: require('../assets/icon/yhu.png')
                        },
                    ]
                }
            ],
            containerHeight: {
                height: ""
            },
            activeIndex: '',
            type: null,
            isCollapse: false,
            name: false
        }
    },
    mounted() {
        this.type = localStorage.getItem('type')
        console.log(this.type);
        if (localStorage.getItem('type') == 2) {
            this.items.forEach(item => {
                if (item.name == '官网管理') {
                    console.log(item.name);
                    console.log(item);
                    this.listItem.push(item)
                    this.activeIndex = item.children[0].path
                    this.$router.push('/cats/managementTs/information');
                }
            })
        } else {
            this.activeIndex = '/cat/Catts'
            this.listItem = this.items
        }
        if (this.isCollapse == false) {
            document.querySelector('.leftMenu-text').style.display = 'none'
        }
    },
    methods: {
        handleSelect(key, keyPath, name) {
            if (key == '/cat/managementSend/changePassword') {
                this.$router.push({ name: "changePassword", params: { num: 1 } })
            }
        },
        // 退出登录
        logoutOfLogin() {
            this.$confirm('确定退出吗 ?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                sessionStorage.removeItem('token');
                this.$router.replace('/');
            })


        },
        // 展开收缩
        expand() {
            console.log(666);
            this.isCollapse = !this.isCollapse
            if (this.isCollapse != false) {
                document.querySelector('.leftMenu-text').style.display = 'block'

            } else {
                document.querySelector('.leftMenu-text').style.display = 'none'
            }
        }
    },
    created() {

        //动态调整左侧菜单栏高度
        var docHeight = document.documentElement.clientHeight;
        this.containerHeight.height = docHeight - 20 + "px";
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 0;
}

body>.el-container {
    margin-bottom: 40px;
}


::v-deep .el-submenu .el-menu-item {
    min-width: auto;
}

::v-deep .el-menu {
    width: 100%;
    height: 100%;
    background-color: #1E90FF;
}

::v-deep .el-row {
    height: 100%;
}

.main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.header {
    // position: absolute;
    // left: 200px;
    // top: 0;
    // right: 0;
    background-color: #333;
    height: 60px;
    display: flex;
    align-items: center;
}

.leftMenu {
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
    // background-color: #d43eff;
    width: 200px;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;

    .leftMenu-text {
        writing-mode: vertical-lr;
        /*垂直展示*/
        margin: 0 auto;
    }
}

.mainContent {
    position: absolute;
    left: 200px;
    top: 60px;
    right: 0px;
    bottom: 0;
    // background-color: #37ff42;
}

</style>
