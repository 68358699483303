<template>
  <div class="tab">
    <div class="tab-a">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="客户名称" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请选择客户名称"></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSubmitsearch()" style="margin-left: 15px;" size="medium">搜索</el-button>
        <el-button type="primary" @click="onSubmitReset()" size="medium">重置</el-button>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="tab-b">
      <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading">
        <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
        </el-table-column>
        <el-table-column prop="companyName" label="客户名称" align="center" width="180">
        </el-table-column>
        <el-table-column prop="transactionID" label="充值订单号" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="付款方式" align="center" width="150">
          <template slot-scope="scope">
            {{
              scope.row.payMode == '1' ? '支付宝支付' : scope.row.payMode == '2' ? '微信支付' : scope.row.payMode == '3' ? '银行转账' :
                ''
            }}
          </template>
        </el-table-column>
        <el-table-column prop="transMoney" label="付款金额" align="center" width="155">
        </el-table-column>
        <el-table-column prop="transScore" label="兑换积分" align="center">
        </el-table-column>
        <el-table-column prop="transDatetime" label="付款时间" align="center" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template slot-scope="scope">
            {{
              scope.row.status == 'Y' ? '已审核' : '未审核'
            }}
          </template>
        </el-table-column>
        <el-table-column prop="transCert" label="付款截图" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="transDesc" label="说明" width="150" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column label="操作" width="170" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="success" size="small" @click="editDanger(scope.row)"> 审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum" :page-sizes="[20, 40, 60, 100]" :page-size="queryInfo.pagesize"
        :total="schArr.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important;">
      </el-pagination>
      <!-- 审核 -->
      <el-dialog title="提示" :visible.sync="centerDialogVisibleen" width="20%" center :close-on-click-modal="false">
        <span>你审核好了吗, 是否继续?</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisibleen = false" type="info" size="medium">取 消</el-button>
          <el-button type="success" @click="confirmUnbinding()" size="medium">继 续</el-button>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>

import { findIntegral, updateYstatus } from '@/utils/api'
export default {
  data() {
    return {
      queryInfo: {
        pagenum: 1,
        pagesize: 20
      },
      form: {
        companyName: ''
      },
      tableListt: [],
      schArr: [],
      loading: false,
      centerDialogVisibleen: false,
      params: {
        transactionID: '',
        status: '',
        transScore: '',
        compangID: ''
      },
      imgSrc: []
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.queryInfo.pagesize = val
      this.getPageData()
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.queryInfo.pagenum = val
      this.getPageData()
      console.log(`当前页: ${val}`);
    },
    getPageData() {
      let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
      let end = start + this.queryInfo.pagesize;
      this.schArr = this.tableListt.slice(start, end);
      this.$forceUpdate()
    },
    // type序号 - 页面切换递增
    indexAdd(index) {
      const page = this.queryInfo.pagenum // 当前页码
      const pagesize = this.queryInfo.pagesize  // 当前页码
      return index + 1 + (page - 1) * pagesize
    },
    // 搜索
    onSubmitsearch() {
      this.listDataPoints()
    },
    // 重置
    onSubmitReset() {
      this.$refs.form.resetFields();
      this.loading = true
      setTimeout(() => {
        this.listDataPoints()
      }, 500);
    },
    // 审核
    editDanger(val) {
      this.params.transactionID = val.transactionID
      this.params.status = val.status
      this.params.transScore = val.transScore
      this.params.compangID = val.custIDin
      this.centerDialogVisibleen = true
    },
    confirmUnbinding() {
      updateYstatus(this.params).then(res => {
        if (res.data.syscode == '0000') {
          this.$message({
            message: res.data.retValue,
            type: 'success'
          });
          this.centerDialogVisibleen = false
          this.listDataPoints()
        }
      })
    },

    // 数据列表
    listDataPoints() {
      findIntegral(this.form).then(res => {
        if (res.data.syscode == '0000') {
          this.tableListt = res.data.IntegralList
          this.loading = false
          this.getPageData()

        }
      })
    }
  },
  mounted() {
    this.listDataPoints()
  }

};
</script>
  
<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .tab-a {
    width: 100%;
    height: 8%;
    // background-color: red;
    display: flex;
    align-items: center;

    ::v-deep .el-form {
      display: flex;
      align-items: center;
    }

    ::v-deep .el-form-item {
      margin-bottom: 0px;
    }
  }

  .tab-b {
    width: 100%;
    height: 92%;
    // background-color: aqua;
  }
}
</style>
  
