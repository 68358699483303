<template>
  <div class="tab">
    <div class="tab-a">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="客户名称" prop="companyName">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
        <el-form-item label="账户状态" prop="enabled">
          <el-select v-model="form.enabled" placeholder="请选择活动区域">
            <el-option label="已激活" value="Y"></el-option>
            <el-option label="未激活" value="N"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" @click="onSubmitsearch()" style="margin-left: 15px;" size="medium">搜索</el-button>
        <el-button type="primary" @click="onSubmitReset()" size="medium">重置</el-button>
        <el-button type="primary" @click="newCustomers()" size="medium">新增客服</el-button>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="tab-b">
      <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading">
        <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
        </el-table-column>
        <el-table-column prop="servicerID" label="客服ID" align="center">
        </el-table-column>
        <el-table-column prop="companyName" label="客户名称" align="center">
        </el-table-column>
        <el-table-column prop="loginName" label="客户账号" align="center">
        </el-table-column>
        <el-table-column prop="password" label="密码" align="center">
        </el-table-column>
        <el-table-column prop="niceName" label="昵称" align="center">
        </el-table-column>
        <el-table-column prop="image" label="头像" align="center">
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            {{
              scope.row.enable == 'Y' ? '已激活' : scope.row.enable == 'N' ? '未激活' : ''
            }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center">
        </el-table-column>
        <el-table-column label="操作" width="230" align="center">
          <template slot-scope="scope">
            <el-button type="danger" size="small" @click="disaBle(scope.row)">禁 用</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum" :page-sizes="[20, 10000, 100000]" :page-size="queryInfo.pagesize"
        :total="schArr.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important;">
      </el-pagination>
    </div>
    <!-- !-- 弹框 -->
    <el-dialog title="新增客户" :visible.sync="dialogVisible" center :append-to-body='true' custom-class="lzg-dialog-height"
      :lock-scroll="false"  :close-on-click-modal="false">
      <subPage :inputName="name" v-on:closeMain='closeMain'></subPage>
    </el-dialog>
    <!-- 禁用 -->
    <el-dialog title="提示" :visible.sync="centerDialogVisibleen" width="20%" center :close-on-click-modal="false">
      <span>是否禁用该用户, 是否继续?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisibleen = false" type="info" size="medium">取 消</el-button>
        <el-button type="danger" @click="confirmUnbinding()" size="medium">继 续</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import subPage from '@/components/secondlevelPage/subPage.vue'
import { findService, setServiceEnable } from '@/utils/api'
export default {
  components: {
    subPage
  },
  data() {
    return {
      queryInfo: {
        pagenum: 1,
        pagesize: 20
      },
      name: '0',
      form: {
        companyName: '',
        enabled: ''
      },
      tableData: [],
      dialogVisible: false,
      loading: false,
      centerDialogVisibleen: false,
      params: {
        servicerID: '',
        enable: ''
      },
      schArr: []
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    // type序号 - 页面切换递增
    indexAdd(index) {
      const page = this.queryInfo.pagenum // 当前页码
      const pagesize = this.queryInfo.pagesize  // 当前页码
      return index + 1 + (page - 1) * pagesize
    },
    getPageData() {
      let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
      let end = start + this.queryInfo.pagesize;
      this.schArr = this.tableData.slice(start, end);
      this.$forceUpdate()
    },
    // 新增客服
    newCustomers() {
      this.name = '3'
      this.dialogVisible = true
    },
    // 搜索
    onSubmitsearch() {
      this.dataListMse()
    },
    // 重置
    onSubmitReset() {
      this.$refs.form.resetFields();
      this.loading = true
      setTimeout(() => {
        this.dataListMse()
      }, 500);
    },
    // 禁用
    disaBle(val) {
      this.centerDialogVisibleen = true
      this.params.servicerID = val.servicerID
      this.params.enable = val.enable
    },
    confirmUnbinding() {
      setServiceEnable(this.params).then(res => {
        if (res.data.syscode == '0000') {
          this.$message({
            message: res.data.retValue,
            type: 'success'
          });
          this.centerDialogVisibleen = false
          this.dataListMse()
          this.getPageData()
        }
      })
    },
    // 接收子组件传来的参数
    closeMain(val) {
      this.dialogVisible = val

      if (val == false) {
        this.dataListMse()
        this.loading = true
      }
    },
    // 表格数据列表
    dataListMse() {
      let params = {
        companyName: this.form.companyName,
        enabled: this.form.enabled == '' ? '0' : this.form.enabled
      }
      findService(params).then(res => {
        if (res.data.syscode == '0000') {
          this.tableData = res.data.MessageList
          this.loading = false
        }
      })
    }
  },
  mounted() {
    this.dataListMse()
  }
};
</script>
  
<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .tab-a {
    width: 100%;
    height: 8%;
    // background-color: red;
    display: flex;
    align-items: center;
  }

  .tab-b {
    width: 100%;
    height: 92%;
    // background-color: aqua;
  }
}

::v-deep .el-form {
  display: flex;
  align-items: center;
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}
</style>
  
