<template>
  <div class="site-wrapper site-page--login">
    <div class="site-content__wrapper">
      <div class="site-content">
        <div class="brand-info">
          <h2 class="brand-info__text">武汉定制推远营系统</h2>
          <p class="brand-info__intro">一站式出海找定制推</p>
        </div>
        <a class="bottom_text" href="https://beian.miit.gov.cn">
          版权所有：武汉订制推网络科技有限公司 鄂ICP备2023002764号-1
        </a>
        <div class="login-main">
          <h3 class="login-title">登录</h3>
          <el-form
            :model="formLabelAlign"
            :rules="dataRule"
            ref="formLabelAlign"
            @keyup.enter.native="dataFormSubmit()"
            status-icon
          >
            <el-form-item prop="userName">
              <el-input
                v-model="formLabelAlign.loginname"
                placeholder="帐号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="formLabelAlign.password"
                type="password"
                placeholder="密码"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                class="login-btn-submit"
                type="primary"
                @click="login('formLabelAlign')"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ConsoleLogin } from "@/utils/api";
//   import cesfdfds from './HelloWorld.vue'
export default {
  data() {
    return {
      // 表单验证
      rules: {
        // 设置账户效验规则
        loginname: [
          { required: true, message: "请输入账户", trigger: "blur" },
          // { min: 1, max: 11, message: '长度在 3 到 10 个字符的账户', trigger: 'blur' }
        ],
        // 设置密码效验规则
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          // { min: 1, max: 6, message: '长度在 6 到 15 个字符的密码', trigger: 'blur' }
        ],
        // 设置验证码效验规则
        // code: [
        //   { required: true, message: '请输入验证码', trigger: 'blur' },
        //   // { min: 5, max: 5, message: '长度为 5 个字符', trigger: 'blur' }
        // ]
      },
      labelPosition: "left",
      formLabelAlign: {
        loginname: "",
        password: "",
      },
      checked: false,
    };
  },
  methods: {
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          /* ------ 账号密码的存储 ------ */
          if (this.checked) {
            // let password = Base64.encode(this.loginForm.password); // base64加密
            localStorage.setItem("loginname", this.formLabelAlign.loginname);
            localStorage.setItem("password", this.formLabelAlign.password);
          } else {
            localStorage.removeItem("loginname");
            localStorage.removeItem("password");
          }
          this.loginNameList();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    loginNameList() {
      ConsoleLogin(this.formLabelAlign).then((res) => {
        if (res.data.syscode == "0000") {
          this.$message({
            message: res.data.retValue,
            type: "success",
          });
          localStorage.setItem("companyid", res.data.user.companyid);
          localStorage.setItem("loginName", res.data.user.loginName);
          //权限标识
          localStorage.setItem("type", res.data.user.type);
          localStorage.setItem("loginName", res.data.user.loginName);
          // localStorage.setItem('tokenIn',res.data.token)
          sessionStorage.setItem("token", res.data.token);
          this.$router.push("/cat/Catts");
        } else {
          this.$message({
            message: res.data.retValue,
            type: "error",
          });
        }
      });
    },
  },
  mounted() {
    let account = localStorage.getItem("loginname");
    let pwd = localStorage.getItem("password");
    if (account) {
      this.formLabelAlign.loginname = account;
      this.formLabelAlign.password = pwd;
      this.checked = true;
    }
    setTimeout(() => {}, 3000);
  },
};
</script>
  
<style lang="less" scoped>
.site-wrapper.site-page--login {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(38, 50, 56, 0.6);
  overflow: hidden;

  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../assets/images/log1.jpg");
    background-size: cover;
  }

  .site-content__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    // overflow-x: hidden;
    // overflow-y: auto;
    background-color: transparent;
  }

  .site-content {
    min-height: 100%;
    padding: 30px 500px 30px 0;
  }

  .brand-info {
    margin: 220px 100px 0 90px;
    color: #fff;
  }

  .brand-info__text {
    margin: 0 0 22px 0;
    font-size: 48px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .brand-info__intro {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.58;
    opacity: 0.6;
  }

  .login-main {
    position: absolute;
    top: 0;
    right: 0;
    padding: 150px 60px 180px;
    width: 470px;
    min-height: 100%;
    background-color: #fff;
  }

  .login-title {
    font-size: 16px;
  }

  .login-captcha {
    overflow: hidden;

    img {
      width: 100%;
      cursor: pointer;
    }
  }

  .login-btn-submit {
    width: 100%;
    margin-top: 38px;
  }
  .bottom_text {
    width: calc(100% - 590px);
    text-align: center;
    color: #ffffff;
    position: absolute;
    // width: 100%;
    // text-align: center;
    bottom: 20px;
    text-decoration: none;
  }
}
</style>
  
