<template>
    <div class="mas">
        <div class="mas-a">
            <div class="text">修改密码</div>
            <div class="text-a">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="旧密码:" prop="oldPassword">
                        <el-input v-model="form.oldPassword"
                            onkeyup="value=value.replace(/[\W]/g,'')"></el-input>
                    </el-form-item>
                    <el-form-item label="新密码:" prop="newPassword">
                        <el-input v-model="form.newPassword"
                            onkeyup="value=value.replace(/[\W]/g,'')"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码:" prop="name">
                        <el-input v-model="name"></el-input>
                    </el-form-item>
                </el-form>
                <div style="width: 100%; text-align: center; color: red;">
                    <h5>注意：密码最多只能输入八位英文字母，数字且不能存在空格</h5>
                </div>
            </div>
            <div class="bottom-a">
                <el-button type="primary" style="width: 40%;" @click="changePassUen()">修 改</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { changePassword } from '@/utils/api'
//   import cesfdfds from './HelloWorld.vue'
export default {
    data() {
        return {
            form: {
                oldPassword: '',
                newPassword: '',
                companyid: localStorage.getItem('companyid'),
                loginName: localStorage.getItem('loginName')
            },
            name: ''
        };
    },
    methods: {
        changePassUen() {
            if (this.name == this.form.newPassword) {
                changePassword(this.form).then(res => {
                    if (res.data.syscode == '0000') {
                        this.$message({
                            message: res.data.retValue,
                            type: 'success'
                        });
                        this.$refs.form.resetFields();
                        this.name = ''
                    }
                })
            } else {
                this.$message({
                    message: '两次密码不相同哦 !',
                    type: 'error'
                });
            }

        }

    },
    mounted(){
        let a = this.$route.params.num
        localStorage.setItem('name',a)
    }
};
</script>
  
<style lang="less" scoped>
.mas {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .mas-a {
        width: 50%;
        height: 50%;
        border: 1px solid rgb(64, 158, 255);
        border-radius: 5px;
        // background-color: red;

        .text {
            width: 100%;
            height: 10%;
            // background-color: aqua;
            font-size: 30px;
            font-weight: 黑体;
            text-align: center;
            color: rgb(64, 158, 255);
        }

        .text-a {
            width: 100%;
            height: 80%;
            // background-color: aliceblue;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: center;
        }

        .bottom-a {
            width: 100%;
            height: 10%;
            // background-color: aqua;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

::v-deep .el-form {
    width: 45%;
}

::v-deep .el-form-item {
    margin-bottom: 40px;
}
</style>
  
