import { instance } from '../utils/http.js'
import axios from 'axios'

const http = instance
// 登录
export function ConsoleLogin(query) {
    return http({
        url: 'console/ConsoleLogin',
        method: 'post',
        params: query
    })
}
// 客服信息管理
export function findMessage(query) {
    return http({
        url: 'console/findMessage',
        method: 'post',
        params: query
    })
}
// 客服信息管理新增客服
export function addCustome(query) {
    return http({
        url: 'console/addCustomer',
        method: 'post',
        params: query
    })
}
// 客服信息管理编辑
export function editCustomer(query) {
    return http({
        url: 'console/editCustomer',
        method: 'post',
        params: query
    })
}

// 客服信息管理客服通道
export function seachChannel(query) {
    return http({
        url: 'console/seachChannel',
        method: 'post',
        params: query
    })
}
// 客服信息管理客服添加通道
export function addChannel(query) {
    return http({
        url: 'console/addChannel',
        method: 'post',
        params: query
    })
}
// 客服账户管理
export function findAccountManage(query) {
    return http({
        url: 'console/findAccountManage',
        method: 'post',
        params: query
    })
}
// 客服账户管理账号状态
export function setEnable(query) {
    return http({
        url: 'console/setEnable',
        method: 'post',
        params: query
    })
}
// 客服账户管理解绑
export function setIPState(query) {
    return http({
        url: 'console/setIPState',
        method: 'post',
        params: query
    })
}
// 客服账户管理新增账号客户管理
export function importUserName(query) {
    return http({
        url: 'console/importUserName',
        method: 'post',
        params: query
    })
}
// 客服账户管理新增账号
export function addAccount(query) {
    return http({
        url: 'console/addAccount',
        method: 'post',
        params: query
    })
}
// 客服日志查询
export function findUserLog(query) {
    return http({
        url: 'console/findUserLog',
        method: 'post',
        params: query
    })
}
// 客服服务管理
export function findService(query) {
    return http({
        url: 'console/findService',
        method: 'post',
        params: query
    })
}
// 客服服务管理禁用
export function setServiceEnable(query) {
    return http({
        url: 'console/setServiceEnable',
        method: 'post',
        params: query
    })
}
// 客服服务管理新增客服
export function addServicers(query) {
    return http({
        url: 'console/addServicers',
        method: 'post',
        params: query
    })
}
// 客服财务客户积分管理
export function findIntegral(query) {
    return http({
        url: 'console/findIntegral',
        method: 'post',
        params: query
    })
}
// 客服财务客户积分管理审核
export function updateYstatus(query) {
    return http({
        url: 'console/updateYstatus',
        method: 'post',
        params: query
    })
}
// 客服明细账单
export function findCustomerBill(query) {
    return http({
        url: 'console/findCustomerBill',
        method: 'post',
        params: query
    })
}
// 客服单价说明
export function findKhMsgPrice(query) {
    return http({
        url: 'console/findKhMsgPrice',
        method: 'post',
        params: query
    })
}
// 客服单价说明修改
export function editPrice(query) {
    return http({
        url: 'console/editPrice',
        method: 'post',
        params: query
    })
}
// 消息管理消息模板
export function findWsMsgTemplate(query) {
    return http({
        url: 'console/findWsMsgTemplate',
        method: 'post',
        params: query
    })
}
// 消息管理回复消息模板
export function findWsMsgReplyTemplate(query) {
    return http({
        url: 'console/findWsMsgReplyTemplate',
        method: 'post',
        params: query
    })
}
// 消息管理消息发送任务
export function findMsgSchedTask(query) {
    return http({
        url: 'console/findMsgSchedTask',
        method: 'post',
        params: query
    })
}
// 统计报表充值统计
export function findRechargeByTime(query) {
    return http({
        url: 'console/findRechargeByTime',
        method: 'post',
        params: query
    })
}
// 统计报表账单统计
export function findTransactionLog(query) {
    return http({
        url: 'console/findTransactionLog',
        method: 'post',
        params: query
    })
}
// 、用户管理
export function findUserByNameAndId(query) {
    return http({
        url: 'console/findUserByNameAndId',
        method: 'post',
        params: query
    })
}
// 修改密码
export function changePassword(query) {
    return http({
        url: 'console/changePassword',
        method: 'post',
        params: query
    })
}
// 选择平台
export function findDataSourceList(query) {
    return http({
        url: 'system/findDataSourceList',
        method: 'post',
        params: query
    })
}

// 导入关键词system/findIndustryList
export function findIndustryList(query) {
    return http({
        url: 'system/findIndustryList',
        method: 'post',
        params: query
    })
}
// 日志查询
export function findServiceLog(query) {
    return http({
        url: 'console/findServiceLog',
        method: 'post',
        params: query
    })
}
//修改硬件ID
export function findServupdate(query) {
    return http({
        url: 'console/update',
        method: 'put',
        params: query
    })
}
//查询官网提交的数据
export function findServulist(query) {
    return http({
        url: 'subminInfo/list',
        method: 'get',
        params: query
    })
}
//上传图片
export function findSerupload(params) {
    return http({
        url: 'gw/img/upload',
        method: 'post',
        data: params
    })
}
//是否验证地区
export function findSerflag(params) {
    return http({
        url: '/update/login/flag',
        method: 'put',
        params: params
    })
}
//查询富文本
export function findSertext(params) {
    return http({
        url: '/gw/img/text',
        method: 'get',
        params: params
    })
}
//删除富文本
export function findSerdel(params) {
    return http({
        url: '/gw/del/text',
        method: 'delete',
        params: params
    })
}
//修改富文本
export function findSerupdate(params) {
    return http({
        url: '/gw/update/text',
        method: 'post',
        data: params
    })
}


