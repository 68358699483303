import axios from 'axios'
import { Loading, Message } from 'element-ui'    // 这里我是使用elementUI的组件来给提示
import router from '@/router'

let loadingInstance = null     // 加载全局的loading

const instance = axios.create({    //创建axios实例，在这里可以设置请求的默认配置
  timeout: 10000, // 设置超时时间10s
  // baseURL:'http://180.76.164.194:8001/'
  // baseURL: 'http://test.lefenglian.top'
  baseURL: 'http://106.13.213.246:8001'
  // baseURL:'http://192.168.1.24:8001/'
  // baseURL:'http://192.168.1.66:8001/'
})
// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

let httpCode = {        //这里我简单列出一些常见的http状态码信息，可以自己去调整配置
  400: '请求参数错误',
  401: '权限不足, 请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误',
  504: '网关超时'
}

/** 添加请求拦截器 **/
instance.interceptors.request.use(config => {
  config.headers['token'] = sessionStorage.getItem('token') || ''
  if (config.method === 'get') { // 添加时间戳参数，防止浏览器（IE）对get请求的缓存
    config.params = {
      ...config.params,
      t: new Date().getTime()
    }
  }
  // 在这里：可以根据业务需求可以在发送请求之前做些什么:例如我这个是导出文件的接口，因为返回的是二进制流，所以需要设置请求响应类型为blob，就可以在此处设置。
  if (config.url.includes('pur/contract/export')) {
    config.headers['responseType'] = 'blob'
  }
  // 我这里是文件上传，发送的是二进制流，所以需要设置请求头的'Content-Type'
  if (config.url.includes('pur/contract/upload')) {
    config.headers['Content-Type'] = 'multipart/form-data'
  }
  return config
}, error => {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(response => {
  if (response.data.code === 10010 || response.data.code === 10011) {
    // Storage.localRemove('token') // 删除已经失效或过期的token（不删除也可以，因为登录后覆盖）
    router.replace({
      path: '/' // 到登录页重新获取token
    })
  } else if (response.data.token) { // 判断token是否存在，如果存在说明需要更新token
    // Storage.localSet('token', response.data.token) // 覆盖原来的token(默认一天刷新一次)
  }
  return response
}, function (error) {
  return Promise.reject(error)
})



/* 统一封装get请求 */
export const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'get',
      url,
      params,
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      reject(error)
    })
  })
}

/* 统一封装post请求  */
export const post = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      method: 'post',
      url,
      data: data, // 使用 FormData 作为请求数据
      ...config
    }).then(response => {
      console.log(response);
      resolve(response);
    }).catch(error => {
      console.log(error);
      reject(error);
    });
  });
}


export { instance }