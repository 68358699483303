<template>
  <div class="tab">
    <div class="tab-a">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="国家代码:" prop="countryCode">
          <el-input v-model="form.countryCode" placeholder="请选择国家代码"></el-input>
        </el-form-item>
        <el-form-item label="地区名称:" prop="countryName">
          <el-input v-model="form.countryName" placeholder="请选择地区名称"></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSubmitsearch()" style="margin-left: 15px;" size="medium">搜索</el-button>
        <el-button type="primary" @click="onSubmitReset()" size="medium">重置</el-button>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="tab-b">
      <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading">
        <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
        </el-table-column>
        <el-table-column prop="countryCode" label="国家代码" align="center">
        </el-table-column>
        <el-table-column prop="countryName" label="地区名称" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="sendPrice" label="发送价(欧元)" align="center" width="150">
        </el-table-column>
        <el-table-column prop="acceptPrice" label="接受价(欧元)" align="center" width="155">
        </el-table-column>
        <el-table-column prop="sendScore" label="发送(积分)" align="center">
        </el-table-column>
        <el-table-column prop="acceptScore" label="积分" align="center" width="110" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="addiScore" label="附件加费(积分)" align="center">
        </el-table-column>
        <el-table-column label="操作" width="170" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="success" size="small" @click="editDanger(scope.row)"> 修改</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum" :page-sizes="[20, 40, 60, 100]" :page-size="queryInfo.pagesize"
        :total="schArr.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important;">
      </el-pagination>
      <!-- 审核 -->
      <el-dialog title="提示" :visible.sync="centerDialogVisibleen" width="40%" center :close-on-click-modal="false">
          <modeify :inputName="listData" v-on:closeMain='closeMain'></modeify>
      </el-dialog>
    </div>

  </div>
</template>

<script>

import { findKhMsgPrice } from '@/utils/api'
import modeify from '@/components/cats/financial/modify.vue'
export default {
  components:{
    modeify
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      queryInfo: {
        pagenum: 1,
        pagesize: 20
      },
      form: {
        countryCode: '',
        countryName: '',
      },
      tableListt: [],
      schArr: [],
      loading: false,
      centerDialogVisibleen: false,
      params: {
        transactionID: '',
        status: '',
        transScore: '',
        compangID: ''
      },
      listDataType: [
        { name: '充值', value: '1' },
        { name: '消费', value: '2' },
        { name: '提现', value: '3' },
        { name: '转出', value: '4' },
        { name: '转入', value: '5' },
        { name: '订单兑换', value: '6' },
        { name: '退费', value: '7' },
      ],
      listData:''
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.queryInfo.pagesize = val
      this.getPageData()
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.queryInfo.pagenum = val
      this.getPageData()
      console.log(`当前页: ${val}`);
    },
    getPageData() {
      let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
      let end = start + this.queryInfo.pagesize;
      this.schArr = this.tableListt.slice(start, end);
      this.$forceUpdate()
    },
    // type序号 - 页面切换递增
    indexAdd(index) {
      const page = this.queryInfo.pagenum // 当前页码
      const pagesize = this.queryInfo.pagesize  // 当前页码
      return index + 1 + (page - 1) * pagesize
    },
    // 搜索
    onSubmitsearch() {
      this.listDataPoints()
    },
    // 重置
    onSubmitReset() {
      this.$refs.form.resetFields();
      this.loading = true
      setTimeout(() => {
        this.listDataPoints()
      }, 500);
    },
    // 修改centerDialogVisibleen
    editDanger(val){
      this.listData = val
      this.centerDialogVisibleen = true
    },
     // 接收子组件传来的参数
     closeMain(val) {
      this.centerDialogVisibleen = val
      if (val == false) {
        this.listDataPoints()
        this.loading = true
      }
    },
    // 数据列表
    listDataPoints() {
      findKhMsgPrice(this.form).then(res=>{
        if(res.data.syscode == '0000') {
          this.tableListt = res.data.MsgPricelist
          this.loading = false
          this.getPageData()
        }
        console.log(res);
      })
    },
   
  },
  mounted() {
    this.listDataPoints()
  }

};
</script>
  
<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .tab-a {
    width: 100%;
    height: 8%;
    // background-color: red;
    display: flex;
    align-items: center;

    ::v-deep .el-form {
      display: flex;
      align-items: center;
    }

    ::v-deep .el-form-item {
      margin-bottom: 0px;
    }
  }

  .tab-b {
    width: 100%;
    height: 92%;
    // background-color: aqua;
  }
}
</style>
  
