<template>
    <div class="tab">
        <div class="tab-a">
            <el-select v-model="queryInfo.typeName" placeholder="请选择" style="margin-right: 30px;">
                <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.label">
                </el-option>
            </el-select>
            <el-button type="success" size="medium" @click="onSubmitsearch">查询</el-button>
            <el-button type="primary" size="medium" @click="onSubmitReset">重置</el-button>
        </div>
        <!-- 表格 -->
        <div class="tab-b">
            <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading" class="table">
                <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
                </el-table-column>
                <el-table-column prop="id" label="ID" align="center">
                </el-table-column>
                <el-table-column prop="typeName" label="类别" align="center">
                </el-table-column>
                <el-table-column prop="dataValue" label="内容" align="center" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column prop="createTime" label="提交时间" align="center" :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="操作" width="250" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="danger" size="mini" @click="centerDialog(scope.row.id)"
                            style="margin-right: 10px;">
                            删除</el-button>
                        <el-button type="warning" size="mini" @click="centerModify(scope.row)">修改</el-button>

                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.pagenum" :page-sizes="[20, 50, 150]" :page-size="queryInfo.pagesize"
                :total="tableListt.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important;">
            </el-pagination>
            <!-- 修改弹框 -->
            <el-dialog title="修改富文本内容" :visible.sync="dialogVisible">
                <div style="width: 100%; height: 8%;">
                    <el-input v-model="input" :disabled="true"></el-input>
                </div>
                <div style="width: 100%; height: 50%;">
                    <!-- 富文本 -->
                    <quill-editor ref="myQuillEditor" v-model="content" :options="editorOption"
                        @on-success="onEditorChange($event)">
                    </quill-editor>
                </div>
                <div style="width: 100%; height: 10%; display: flex; justify-content: center; align-items: center;">
                    <el-button type="success" size="medium" @click="modifyRichText" style="margin-top: 10px;">修改富文本</el-button>
                </div>
            </el-dialog>
            
        </div>

    </div>
</template>
    
<script>
import { findSertext, findSerdel, findSerupdate } from '@/utils/api'
import axios from 'axios'
export default {
    data() {
        return {
            queryInfo: {
                pagenum: 1,
                pagesize: 20,
                typeName: ''
            },
            optionss: [
                {
                    label: '外贸动态'
                }, {
                    label: '行业案例'
                },
                {
                    label: '外贸知识'
                }, {
                    label: '公司荣誉'
                },
            ],
            tableListt: [],
            schArr: [],
            loading: false,
            dialogVisible: false,
            content: '',
            editorOption: {
                modules: {
                    toolbar: [
                        ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
                        ["blockquote", "code-block"], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
                        [{ script: "sub" }, { script: "super" }], // 上标/下标
                        [{ indent: "-1" }, { indent: "+1" }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ["small", false, "large", "huge"] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ["clean"], // 清除文本格式
                        ["image"] // 链接、图片、视频
                    ], //工具菜单栏配置
                },
                placeholder: '请在这里添加文案', //提示
                readyOnly: false, //是否只读
                theme: 'snow', //主题 snow/bubble
                syntax: true, //语法检测

            },
            input: '',
            isId: ''
        }
    },
    methods: {
        handleClick(row) {
            console.log(row);
        },
        handleSizeChange(val) {
            this.queryInfo.pagesize = val
            this.getPageData()
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.queryInfo.pagenum = val
            this.getPageData()
            console.log(`当前页: ${val}`);
        },
        getPageData() {
            let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
            let end = start + this.queryInfo.pagesize;
            this.schArr = this.tableListt.slice(start, end);
            this.$forceUpdate()
        },
        // type序号 - 页面切换递增
        indexAdd(index) {
            const page = this.queryInfo.pagenum // 当前页码
            const pagesize = this.queryInfo.pagesize  // 当前页码
            return index + 1 + (page - 1) * pagesize
        },
        // 搜索
        onSubmitsearch() {
            this.listDataPoints()
        },
        // 重置
        onSubmitReset() {
            this.$refs.form.resetFields();
            this.loading = true
            setTimeout(() => {
                this.listDataPoints()
            }, 500);
        },
        // 数据列表
        listDataPoints() {
            findSertext(this.queryInfo).then(res => {
                console.log(res);
                if (res.data.syscode == '0000') {
                    this.tableListt = res.data.MessageList
                    this.loading = false
                    this.getPageData()
                }else {
                    this.tableListt = []
                    this.getPageData()
                }
            })
        },
        //删除
        centerDialog(id) {
            let params = {
                id
            }
            findSerdel(params).then(res => {
                if (res.data.syscode == '0000') {
                    this.$message({
                        message: res.data.reValue,
                        type: 'success'
                    });
                    this.listDataPoints()
                }else {
                    this.$message.error(res.data.reValue);
                }
            })
        },
        onEditorChange(editor) {
            this.content = editor.html;
        },
        //修改
        centerModify(item) {
            this.dialogVisible = true
            this.content = item.dataValue
            this.input = item.typeName
            this.isId = item.id
        },
        //修改富文本
        modifyRichText() {
            let params = {
                dataValue: this.content,
                id: this.isId,
                typeName: this.input
            }
            axios({
                method: 'post',
                data: params,
                url: 'http://106.13.213.246:8001/gw/update/text',
                headers: { 'Token': sessionStorage.getItem('token') }
            }).then(res => {
                if (res.data.syscode == '0000') {
                    this.$message({
                        message: res.data.reValue,
                        type: 'success'
                    });
                    this.dialogVisible = false
                }else {
                    this.$message.error(res.data.reValue);
                }
            })
        }
    },
    mounted() {
        this.listDataPoints()
    }

};
</script>
      
<style lang="less" scoped>
.tab {
    width: 100%;
    height: 100%;

    .tab-a {
        width: 100%;
        height: 5%;
        // background-color: red;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        ::v-deep .el-form {
            display: flex;
            align-items: center;
        }

        ::v-deep .el-form-item {
            margin-bottom: 0px;
        }
    }

    .tab-b {
        width: 100%;
        height: 95%;
        // background-color: aqua;
    }
}
</style>
      
    