<template>
  <div class="tab">
    <div class="tab-a">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="登陆账号" prop="loginName">
          <el-input v-model="form.loginName"></el-input>
        </el-form-item>
        <el-form-item label="用户名称" prop="userName">
          <el-input v-model="form.userName"></el-input>
        </el-form-item>
        <el-button type="primary" @click="onSubmitsearch()" style="margin-left: 15px;" size="medium">搜索</el-button>
        <el-button type="primary" @click="onSubmitReset()" size="medium">重置</el-button>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="tab-b">
      <el-table :data="schArr" border style="width: 100%" height="95%" v-loading="loading">
        <el-table-column type="index" label="序号" align="center" :index="indexAdd" width="60">
        </el-table-column>
        <el-table-column prop="loginName" label="登录账号" align="center">
        </el-table-column>
        <el-table-column prop="userName" label="账号名称" align="center">
        </el-table-column>
        <el-table-column prop="passWord" label="密码" align="center">
        </el-table-column>
        <el-table-column prop="LastLoginDate" label="最后登录日期" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="mac" label="MAC" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="createDateTime" label="账号创建时间" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="serviceEndDate" label="账号到期日期" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="funcAuth" label="功能权限" align="center">
        </el-table-column>
        <el-table-column prop="dataAuth" label="数据权限" align="center">
        </el-table-column>
        <el-table-column prop="telphone" label="电话" align="center" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="remark" label="状态" align="center">
          <template slot-scope="scope">
            {{
              scope.row.enable == 'Y' ? '已启用' : scope.row.enable == 'N' ? '已禁用' : ''
            }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" align="center">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum" :page-sizes="[100, 1000,10000]" :page-size="queryInfo.pagesize"
        :total="tableData.length" layout="->, total, sizes, prev, pager, next, jumper" style="position: 0px 5px !important;">
      </el-pagination>
    </div>
  </div>
</template>
  
<script>
import { findUserByNameAndId } from '@/utils/api'
export default {
  data() {
    return {
      queryInfo: {
        pagenum: 1,
        pagesize: 100
      },
      name: '0',
      form: {
        userName: '',
        loginName: ''
      },
      tableData: [],
      dialogVisible: false,
      loading: false,
      schArr: [],
    }
  },
  methods: {
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.queryInfo.pagesize = val
      this.getPageData()
    },
    handleCurrentChange(val) {
      this.queryInfo.pagenum = val
      this.getPageData()
    },
    // type序号 - 页面切换递增
    indexAdd(index) {
      const page = this.queryInfo.pagenum // 当前页码
      const pagesize = this.queryInfo.pagesize  // 当前页码
      return index + 1 + (page - 1) * pagesize
    },
    getPageData() {
      let start = (this.queryInfo.pagenum - 1) * this.queryInfo.pagesize;
      let end = start + this.queryInfo.pagesize;
      this.schArr = this.tableData.slice(start, end);
      this.$forceUpdate()
    },
    // 搜索
    onSubmitsearch() {
      this.dataListMse()
    },
    // 重置
    onSubmitReset() {
      this.$refs.form.resetFields();
      this.loading = true
      setTimeout(() => {
        this.dataListMse()
      }, 500);
    },
    // 表格数据列表
    dataListMse() {
      findUserByNameAndId(this.form).then(res => {
        if (res.data.syscode == '0000') {
          this.tableData = res.data.usersList
          this.loading = false
          this.getPageData()
        }
      })
    }
  },
  mounted() {
    this.dataListMse()

  }
};
</script>
    
<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .tab-a {
    width: 100%;
    height: 8%;
    // background-color: red;
    display: flex;
    align-items: center;
  }

  .tab-b {
    width: 100%;
    height: 92%;
    // background-color: aqua;
  }
}

::v-deep .el-form {
  display: flex;
  align-items: center;
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}
</style>
    
  